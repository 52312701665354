import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import NewsCard from "../../admin/components/news_card";
import ViewNews from "../../admin/components/view_news";
import Cookies from 'js-cookie';

const NewsBar = () => {
  const [loadingNews, setLoadingNews] = useState(true);
  const [viewStory, setViewStory] = useState();
  const [news, setNews] = useState();
  const apiKey = process.env.REACT_APP_KEY;
  //const [viewStory, setViewStory] = useState();
  const apiUrl = process.env.REACT_APP_API;
  const token = Cookies.get("token_member_highlander");
  
  useEffect(() => {
    getNews();
  }, []);

  const getNews = () => {
    axios
      .get(apiUrl + "latest_news", {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        }
      })
      .then((result) => {
        setNews(result.data.data);
        setLoadingNews(false);
      })
      .catch((error) => {
        setLoadingNews(false);
        setNews("Error try again later");
      });
  };
  return (
    <div
      className="w-full h-[80vh] flex flex-col gap-2 overflow-auto "
      style={{ scrollbarWidth: "thin" }}
    >
      {loadingNews ? (
        <div className="w-full h-[82vh]  flex place-content-center place-items-center">
          {" "}
          <CircularProgress size={13} sx={{ color: "red" }} />{" "}
        </div>
      ) : (
        <div
          className="w-full h-[80vh]  flex flex-col overflow-auto"
          style={{
            scrollbarWidth: "thin",
          }}
        >
          {news === "Error try again later" ? (
            <div className="w-full h-full flex place-content-center place-items-center">
              {" "}
              <p
                className="text-xs cursor-pointer"
                onClick={() => {
                  setLoadingNews(true);
                  getNews();
                }}
              >
                Error loading try again
              </p>{" "}
            </div>
          ) : (
            news.map((value, index) => (
              <div
                onClick={() => {
                  setViewStory(value);
                }}
              >
                <NewsCard key={index + "news"} details={value}></NewsCard>{" "}
              </div>
            ))
          )}
        </div>
      )}
       {viewStory && (
        <div className="w-full h-full bg-white  absolute top-0 left-0 flex flex-col place-items-center">
          <div className="w-full flex place-content-start p-3">
            <ViewNews setViewStory={setViewStory} viewStory={viewStory} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsBar;

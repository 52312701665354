import { Navigate, createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import AdminLogin from "../views/admin_login";
import Login from "../views/login";
import MainMember from "../views/members/main"
import MainAdmin from "../views/admin/main"
import HomeMember from "../views/members/home";
import HomeAdmin from "../views/admin/main_pages/home";
import SettingsAdmin from "../views/admin/main_pages/settings";
import Members from "../views/admin/main_pages/members"
import Privacy from "../views/members/privacy";
import News from "../views/admin/main_pages/news";
import Alerts from "../views/admin/main_pages/alerts";
import MainMembers from "../views/members/main";
import NewsMember from "../views/members/news";
import UpdatesMember from "../views/members/updates";
import Inbox from "../views/members/inbox";
import Terms from "../views/members/terms";
import Players from "../views/admin/main_pages/players";
import PlayersMember from "../views/members/players";
//https://react.dev/reference/react/lazy
const NotFound = lazy(() => import("../views/notfound"));

const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },

  {
    path: "/",
    element: <Navigate to="/member" replace/>
  },
  //Admin login route
  // if user is null or not correct
  {
    path: "/admin",
    element: <AdminLogin />,
  },
  {
    path: "/member",
    element: <Login />,
  },

  {
    path:"/privacy",
    element: <Privacy/>
  },
  {
    path: "/terms",
    element: <Terms/>
  },

  {
    path: "/member",
    element: <MainMember/>,
    children: [
      {
        path: "/member/home",
        element: <HomeMember />,
      },
      {
        path: "/member/inbox",
        element: <Inbox />,
      },
      {
        path: "/member/news",
        element: <NewsMember />,
      },
      {
        path: "/member/players",
        element: <PlayersMember/>
      },
      {
        path: "/member/updates",
        element: <UpdatesMember />,
      },

    ]
  },

  ///Admin views

  {
    path: "/admin",
    element: <MainAdmin/>,
    children: [
      {
        path: "/admin/home",
        element: <HomeAdmin />,
      },
      {
        path:"/admin/members",
        element: <Members/>
      },
      {
        path:"/admin/news",
        element: <News/>
      },
      {
        path:"/admin/players",
        element: <Players/>
      },
      {
        path:"/admin/alerts",
        element: <Alerts/>
      },
      
      {
        path: "/admin/settings",
        element: <SettingsAdmin />,
      },

    ]
  },
]);

export default router;

import { IconButton, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Loading from '../admin/components/loading';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import { months } from '../../utils/months';
import Cookies from 'js-cookie';

const UpdatesMember = () => {
  const [allUpdates, setAllUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const token = Cookies.get("token_member_highlander");

  useEffect(() => {
    getUpdates()
  }, [])
  

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getUpdates = () => {
    
    /// get all updates sent out
    axios
      .get(apiUrl + "updates", {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        }
      })
      .then((result) => {
        setLoading(false);
        setAllUpdates(result.data.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setMessage("Error loading please try again");
        handleClickSnack();
      });
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <div className="w-full border-b h-16 p-3 flex flex-col place-content-center">
        <p className="font-medium text-sm">Updates</p>
        <p className="text-xs text-slate-400">
          Track all changes that have been implemented by the club
        </p>
      </div>
      <div className='w-full h-full'>
      <div className=" w-full h-[80vh] flex flex-col overflow-auto">
        {loading ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <Loading />{" "}
          </div>
        ) : error ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <p
              className="text-xs cursor-pointer"
              onClick={() => {
                setLoading(true);
                setError(false);
                getUpdates();
              }}
            >
              Error loading tap to try again
            </p>{" "}
          </div>
        ) : allUpdates.length === 0 ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <p className="text-xs">No updates found</p>{" "}
          </div>
        ) : (
          <div className="w-full h-full sm:w-[60%] flex flex-col pt-3 overflow-auto" style={{scrollbarWidth: "thin"}}>
            {allUpdates.map((value, index) => (
              <div className="flex gap-5 p-3 pb-0 pt-0" key={"update" + index}>
                <div className="flex flex-col place-items-center">
                  <div className="w-2 h-2 rounded-full flex flex-shrink-0 border border-zinc-500" />
                  <div className="w-[1px] h-full bg-zinc-400" />
                </div>

                <div className="w-full flex flex-col gap-3 p-3">
                  <p className="text-sm text-slate-400 line-clamp-1">
                    {`${
                      months[new Date(value.date_posted).getUTCMonth()]
                    } ${new Date(value.date_posted).getDate()}, ${new Date(
                      value.date_posted
                    ).getUTCFullYear()}`}
                  </p>

                  <p className="text-lg">{value.title}</p>

                  <p className="text-xs">{value.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
      </div>
    </div>
  )
}

export default UpdatesMember
import { Button, CircularProgress, IconButton, Snackbar } from "@mui/material";
import React, { useRef, useState } from "react";
import { AiOutlineClose, AiOutlineEdit, } from "react-icons/ai";
import { MdPersonOutline } from "react-icons/md";
import DialogBackground from "./dialog_background";
import { BsPerson } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { useStyle } from "../../../utils/styles";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import axios from "axios";
import Cookies from "js-cookie";
import app from "../../../utils/app";

const ViewPlayer = ({ player, closeMenu }) => {
  const [popup,setPopup] = useState(false)
  const [editImage,setEditImage] = useState(false)
  const [uploading,setUploading] = useState(false)
  const [img,setImg] = useState(null)
  const hiddenFileInput = useRef(null);
  const [open,setOpen] = useState(false)
  const [errorMsg,setErrorMessage] = useState("")
  const apiUrl = process.env.REACT_APP_API;
  const token = Cookies.get("token_highlander_admin");
  const apiKey = process.env.REACT_APP_KEY;

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);
    setImg(arr[0]);
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const uploadPlayerImage = () => {
    setUploading(true);
    const storage = getStorage(app);
      const ext = img.name.split(".")[1];
      var storageref = ref(
        storage,
        "profiles/" + Date.now() + "_" + player.name + "." + ext
      );
      const uploadTask = uploadBytesResumable(storageref, img.file);
      //promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          setErrorMessage("Error creating member try again");
          handleClickSnack();
          setUploading(false)
          return;
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            axios
              .post(
                apiUrl + "update_player_img",
                {
                  id: player.id,
                  profile_img: downloadUrl,
                  
                },
                {
                  headers: {
                    "API-Key": apiKey,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((result) => {
                
                  setErrorMessage("Player updated");
                  player['profile_img'] = downloadUrl;
                  handleClickSnack();
                  setUploading(false)
               
              })
              .catch((error) => {
                setErrorMessage("Error updating player try again");
                handleClickSnack();
                setUploading(false)
              
              });
            return;
          });
        }
      );
  
  }
  return (
    <div className="w-full h-full bg-white flex flex-col relative">
      <div className="w-full h-10 flex place-items-center border-b p-3 place-content-between">
        <IconButton
          onClick={() => {
            closeMenu(null);
          }}
        >
          <AiOutlineClose size={15} />
        </IconButton>
        <IconButton onClick={() => {setPopup(true)}}>
          <AiOutlineEdit size={15} />
        </IconButton>
      </div>
      <div className="h-[85vh] flex flex-col sm:flex-row  w-full">
        <div className="w-full sm:w-1/2 sm:h-full h-1/2 flex place-content-center place-items-center border border-t-0">
          {player.profile_img === "" ? (
            <MdPersonOutline size={80} />
          ) : (
            <img src={player.profile_img} alt="" className="w-full h-full" />
          )}
        </div>
        <div className="w-full  sm:w-1/2 sm:h-full h-1/2 flex flex-col p-8 gap-3">
          <p className=" text-xs sm:text-4xl font-semibold">{player.number}</p>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">Name:</p>
            <p className="text-sm capitalize">{player.name}</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">Citizenship:</p>
            <p className="text-sm capitalize">{player.citizenship}</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">position:</p>
            <p className="text-sm capitalize">{player.position}</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">weight:</p>
            <p className="text-sm capitalize">{player.weight} kg</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">height:</p>
            <p className="text-sm">{player.height} cm</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">Career beginning:</p>
            <p className="text-sm">{player.career_beginning}</p>
          </div>
        </div>
      </div>

      {
        popup && <div className="w-full h-full  absolute">
          <div className="w-full h-full " onClick={() => {setPopup(false)}}/>
            <div className="shadow-2xl w-32 h-10 absolute right-2 top-8 border bg-white flex place-items-center p-3 cursor-pointer" onClick={() => {
              setPopup(false)
              setEditImage(true)
            }}>
              <p className="text-xs">Upload image</p>
              </div>
           </div>
      }
       {editImage && (
        <div className="w-full h-full absolute z-[999999999] top-0 left-0 flex place-content-center place-items-center">
          <DialogBackground action={setEditImage} value={false} />

          <div className=" w-[95%] sm:w-[30vw] pb-5 bg-white absolute flex flex-col place-items-center pt-10 gap-5">
            {img === null ? (
              <div className="w-full flex place-content-center my-3">
                <div
                  onClick={handleClick}
                  className="w-20 h-20 rounded-full border border-black border-dashed flex place-content-center place-items-center gap-2 cursor-pointer"
                >
                  <BsPerson />
                </div>
              </div>
            ) : (
              <div className="w-full flex  place-content-center">
                <div
                  className="w-20 h-20 border rounded-full relative cursor-pointer my-3"
                  onClick={handleClick}
                >
                  <img
                    src={img.item}
                    alt="Cannot Load"
                    className="w-full h-full rounded-full"
                  />

                  <div
                    className="w-8 h-8 bg-slate-200 absolute right-1 bottom-0 rounded-full cursor-pointer flex place-content-center place-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImg(null);
                    }}
                  >
                    {" "}
                    <IoClose size={13} />{" "}
                  </div>
                </div>
              </div>
            )}

            <input
              hidden
              accept="image/*"
              type="file"
              ref={hiddenFileInput}
              onChange={uploadHandler()}
            />

            <div className="w-1/3 flex place-content-center">
              <Button
                sx={useStyle.Button}
                onClick={() => {
                 if(img !== null){
                  uploadPlayerImage()
                 }
                }}
                endIcon={uploading ? <CircularProgress size={14} /> : <></>}
                fullWidth
              >
                Upload Image
              </Button>
            </div>
          </div>
        </div>
      )}
       <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMsg}
        action={action}
      />
    </div>
  );
};

export default ViewPlayer;

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AiOutlineClose } from "react-icons/ai";
import Loading from "./loading";
import DialogBackground from "./dialog_background";
import utc from "dayjs/plugin/utc";
import { IoClose } from "react-icons/io5";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import app from "../../../utils/app";
import { BsPerson } from "react-icons/bs";
import Cookies from "js-cookie";

const AddMember = ({ setAddMember }) => {
  dayjs.extend(utc);
  const [fullName, setFullName] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [chapters, setChapters] = useState([]);
  const [loadingChapters, setLoadingChapter] = useState(true);
  const [errorChapters, setErrorChapter] = useState(false);
  const [Subscriptions, setSubscriptions] = useState([]);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [errorSubscriptions, setErrorSubscriptions] = useState(false);
  const [memberShipId, setMembershipId] = useState("");
  const [dobDate, setDobDate] = useState(dayjs(new Date()));
  const [joinedDate, setJoinedDate] = useState(dayjs(new Date()));
  const [expiryDate, setExpiryDate] = useState(dayjs(new Date()));
  const [posting, setPosting] = useState(false);
  const [sub, setSub] = useState("");
  const [subId, setSubId] = useState("");
  const [subsVal, setSubVal] = useState("");
  const [chapter, setChapter] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API;
  const [img, setImg] = useState(null);
  const hiddenFileInput = useRef(null);
  const apiKey = process.env.REACT_APP_KEY;
  const token = Cookies.get("token_highlander_admin");

  useEffect(() => {
    console.log(dobDate);
    getChapters();
    getSubs();
  }, []);

  const getChapters = () => {
    axios
      .get(apiUrl + "chapters", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoadingChapter(false);
        setChapters(result.data.data);
        //console.log(result)
      })
      .catch((error) => {
        console.log(error);
        setLoadingChapter(false);
        setErrorChapter(true);
      });
  };
  const getSubs = () => {
    axios
      .get(apiUrl + "subscriptions", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoadingSubscriptions(false);
        setSubscriptions(result.data.data);
        //console.log(result)
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubscriptions(false);
        setErrorSubscriptions(true);
      });
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);
    setImg(arr[0]);
  };

  const handleChangeChapter = (e) => {
    setChapter(e.target.value);
  };
  const handleChangeSubs = (e) => {
    setSub(e.target.value);
    setSubId(e.target.value.id);
    setSubVal(e.target.value.name);
  };

  const dummy = (val) => {};

  const addMember = () => {
    if (fullName === "" || idNumber === "" || chapter === "" || subId === "") {
      setMessage("Please fill in your details");
      handleClickSnack();
      return;
    }

    var dobDt =
      dobDate.$y +
      "-" +
      (dobDate.$M + 1).toString().padStart(2, "0") +
      "-" +
      dobDate.$D.toString().padStart(2, "0") +
      " " +
      dobDate.$H +
      ":" +
      dobDate.$m.toString().padStart(2, "0") +
      " +00:00";

    var joinDt =
      joinedDate.$y +
      "-" +
      (joinedDate.$M + 1).toString().padStart(2, "0") +
      "-" +
      joinedDate.$D.toString().padStart(2, "0") +
      " " +
      joinedDate.$H +
      ":" +
      joinedDate.$m.toString().padStart(2, "0") +
      " +00:00";

    var expDt =
      sub.name.toLowerCase() === "lifetime"
        ? "2099-12-30 12:00 +00:00"
        : expiryDate.$y +
          "-" +
          (expiryDate.$M + 1).toString().padStart(2, "0") +
          "-" +
          expiryDate.$D.toString().padStart(2, "0") +
          " " +
          expiryDate.$H +
          ":" +
          expiryDate.$m.toString().padStart(2, "0") +
          " +00:00";

    setPosting(true);
    //"hello".substring();
    /// Upload the image to storage bucket
    if (img === null) {
      axios
        .post(
          apiUrl + "new_member",
          {
            fullname: fullName,
            member_img: "",
            id_number: idNumber,
            membership_id: memberShipId,
            dateofbirth: dobDt,
            chapter: chapter,
            subscription: subId,
            joined: joinDt,
            expiry: expDt,
            email: email,
            mobile: phone,
            address: address,
            subName: subsVal,
          },
          {
            headers: {
              "API-Key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            setMessage("Member created reload to see the member");
            setFullName("");
            setIdNumber("");
            setMembershipId("");
            setChapter("");
            setSub("");
            setAddress("");
            setPhone("");
            setEmail("");
            setSubId("");
            handleClickSnack();
          } else {
            setMessage("Failed to create member try again");
            handleClickSnack();
          }
          setPosting(false);
        })
        .catch((error) => {
          setMessage("Error creating member try again");
          handleClickSnack();
          setPosting(false);
        });
    } else {
      /// upload image then get the url
      const storage = getStorage(app);
      const ext = img.name.split(".")[1];
      var storageref = ref(
        storage,
        "profiles/" + Date.now() + "_" + fullName + "." + ext
      );
      const uploadTask = uploadBytesResumable(storageref, img.file);
      //promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          setMessage("Error creating member try again");
          handleClickSnack();
          setPosting(false);
          return;
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            axios
              .post(
                apiUrl + "new_member",
                {
                  fullname: fullName,
                  member_img: downloadUrl,
                  id_number: idNumber,
                  membership_id: memberShipId,
                  dateofbirth: dobDt,
                  chapter: chapter,
                  subscription: subId,
                  joined: joinDt,
                  expiry: expDt,
                  email: email,
                  mobile: phone,
                  address: address,
                  subName: subsVal,
                },
                {
                  headers: {
                    "API-Key": apiKey,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((result) => {
                if (result.status === 200) {
                  setMessage("Member created reload to see the member");
                  setFullName("");
                  setIdNumber("");
                  setMembershipId("");
                  setChapter("");
                  setSub("");
                  setAddress("");
                  setPhone("");
                  setEmail("");
                  setSubId("");
                  setImg(null);
                  handleClickSnack();
                } else {
                  setMessage("Failed to create member try again");
                  handleClickSnack();
                }
                setPosting(false);
              })
              .catch((error) => {
                setMessage("Error creating member try again");
                handleClickSnack();
                setPosting(false);
              });
            return;
          });
        }
      );
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="absolute w-full py-5 bg-white rounded-lg z-[99] flex flex-col gap-3 px-3 place-items-center">
      <div className="w-full py-1 border-slate-300 border-b flex place-content-between place-items-end">
        <div className="flex flex-col gap-3">
          {img === null ? (
            <div className="w-20 h-20 border rounded-md border-black border-dashed flex place-content-center place-items-center gap-2 cursor-pointer">
              <BsPerson />
            </div>
          ) : (
            <div
              className="w-20 h-20 border rounded-md relative cursor-pointer"
              onClick={handleClick}
            >
              <img
                src={img.item}
                alt="Cannot Load"
                className="w-full h-full rounded-md"
              />

              <div
                className="w-8 h-8 bg-slate-200 absolute right-1 bottom-0 rounded-md cursor-pointer flex place-content-center place-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  setImg(null);
                }}
              >
                <IoClose size={13} />{" "}
              </div>
            </div>
          )}
          <div>
            <p className="text-sm font-medium text-slate-600">
              Profile picture
            </p>
            <p className="text-xs text-slate-400">PNG, JPEG under 15MB</p>
          </div>
        </div>
        <Button
          variant="outlined"
          sx={useStyle.OutlineButton}
          onClick={handleClick}
        >
          Upload picture
        </Button>
      </div>

      <input
        hidden
        accept="image/*"
        type="file"
        ref={hiddenFileInput}
        onChange={uploadHandler()}
      />
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-name" size="small">
          Full Name
        </InputLabel>
        <OutlinedInput
          id="user-name"
          label="Full Name"
          variant="outlined"
          size="small"
          fullWidth
          value={fullName}
          onChange={(event) => {
            setFullName(event.target.value.toUpperCase());
          }}
          sx={{
            borderRadius: 0,
            height: "40px",
          }}
        />
      </FormControl>
      <div className="flex w-full gap-2">
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-member-id" size="small">
            Member ID
          </InputLabel>

          <OutlinedInput
            id="user-member-id"
            label="Member ID"
            variant="outlined"
            size="small"
            fullWidth
            value={memberShipId}
            onChange={(event) => {
              setMembershipId(event.target.value);
            }}
            sx={{
              borderRadius: 0,
              height: "40px",
            }}
          />

          <FormHelperText>
            <p className="text-xs"> Leave blank if new member </p>
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-id-number" size="small">
            ID Number
          </InputLabel>
          <OutlinedInput
            id="user-id"
            label="Id Number"
            variant="outlined"
            size="small"
            fullWidth
            value={idNumber}
            onChange={(event) => {
              setIdNumber(event.target.value);
            }}
            sx={{
              borderRadius: 0,
              height: "40px",
            }}
          />
        </FormControl>
      </div>

      <div className="w-full flex gap-3">
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-member-phone" size="small">
            Phone Number
          </InputLabel>

          <OutlinedInput
            id="user-member-phone"
            label="Phone Number"
            variant="outlined"
            size="small"
            fullWidth
            value={phone}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
            sx={{
              borderRadius: 0,
              height: "40px",
            }}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-member-email" size="small">
            Email
          </InputLabel>

          <OutlinedInput
            id="user-member-email"
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            sx={{
              borderRadius: 0,
              height: "40px",
            }}
          />
        </FormControl>
      </div>

      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-member-address" size="small">
          Address
        </InputLabel>

        <OutlinedInput
          id="user-member-address"
          label="Address"
          variant="outlined"
          size="small"
          fullWidth
          value={address}
          onChange={(event) => {
            setAddress(event.target.value);
          }}
          sx={{
            borderRadius: 0,
            height: "40px",
          }}
        />
      </FormControl>
      {loadingChapters ? (
        <div className="w-20 h-10 flex place-content-center place-items-center">
          {" "}
          <CircularProgress size={13} />
        </div>
      ) : errorChapters ? (
        <p
          className="text-xs cursor-pointer"
          onClick={() => {
            setLoadingChapter(true);
            setErrorChapter(false);
            getChapters();
          }}
        >
          Error loading please try again
        </p>
      ) : (
        <div className="w-full z-[99999999]">
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label-chapter" size="small">
              Chapter
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-chapter"
              id="demo-simple-select"
              value={chapter}
              label="Chapter"
              onChange={handleChangeChapter}
              size="small"
              style={{
                borderRadius: "0px",
                fontSize: "15px",
              }}
            >
              {chapters.map((value, index) => (
                <MenuItem key={"chapter" + index} value={value.id}>
                  {value.chapter_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {loadingSubscriptions ? (
        <div className="w-20 h-10 flex place-content-center place-items-center">
          {" "}
          <CircularProgress size={13} />
        </div>
      ) : errorSubscriptions ? (
        <p
          className="text-xs cursor-pointer"
          onClick={() => {
            setLoadingSubscriptions(true);
            setErrorSubscriptions(false);
            getSubs();
          }}
        >
          Error loading please try again
        </p>
      ) : (
        <div className="w-full z-[99999999]">
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label-subs" size="small">
              Subscriptions
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-subs"
              id="demo-simple-select"
              value={sub}
              label="Subscriptions"
              onChange={handleChangeSubs}
              size="small"
              style={{
                borderRadius: "0px",
                fontSize: "15px",
              }}
              //MenuProps={{disablePortal:true}}
            >
              {Subscriptions.map((value, index) => (
                <MenuItem key={"chapter" + index} value={value}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="DOB"
          slotProps={{ textField: { size: "small" } }}
          sx={{ borderRadius: "0px", fontSize: "15px", width: "100%" }}
          value={dobDate}
          onChange={(newValue) => setDobDate(newValue)}
        />
      </LocalizationProvider>

      <div className="w-full gap-4 flex flex-col sm:flex-row">
        {subId !== 1 && subId !== "" ? (
          <div className="flex w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expiry"
                sx={{ borderRadius: "0px", fontSize: "15px", width: "100%" }}
                slotProps={{ textField: { size: "small" } }}
                value={expiryDate}
                onChange={(newValue) => setExpiryDate(newValue)}
              />
            </LocalizationProvider>
          </div>
        ) : (
          <></>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date joined"
            sx={{ borderRadius: "0px", fontSize: "15px", width: "100%" }}
            slotProps={{ textField: { size: "small" } }}
            value={joinedDate}
            onChange={(newValue) => setJoinedDate(newValue)}
          />
        </LocalizationProvider>
      </div>

      <div className="flex place-content-end mt-2 w-full">
        <div className="sm:w-1/2 w-full flex  gap-3">
          <Button
            fullWidth
            sx={useStyle.OutlineButton}
            variant="outlined"
            onClick={() => {
              setAddMember(false);
            }}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            onClick={() => {
              addMember();
            }}
            sx={useStyle.Button}
          >
            Add member
          </Button>
        </div>
      </div>

      {posting && (
        <div className="w-full h-full absolute flex place-content-center place-items-center z-[9999]">
          <DialogBackground action={dummy} value={""} />
          <Loading />
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default AddMember;

import { MdHome, MdMessage, MdNewspaper, MdSportsSoccer, MdUpdate } from "react-icons/md";

//Links for admin dashboard
const memberLinks = [
    {
        "title": "Home",
        "link": "/member/home",
        "icons": <MdHome/>
    },
    {
        "title": "Inbox",
        "link": "/member/inbox",
        "icons": <MdMessage/>
    },
    
    {
        "title": "News",
        "link": "/member/news",
        "icons":<MdNewspaper/>
    },
    {
        "title": "Players",
        "link": "/member/players",
        "icons":<MdSportsSoccer/>
    },
    {
        "title": "Updates",
        "link": "/member/updates",
        "icons":<MdUpdate/>
    },
];

export default memberLinks;
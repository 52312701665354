import React, { useState } from "react";
import Header from "./header";
import { Tab, Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";
import Account from "./member/account";
import Overview from "./member/overview";
import Subscription from "./member/subscription";

const ViewMember = ({ viewMember, setViewMember }) => {
  const [value, setValue] = useState(0);

  const tabs = [<Overview details={viewMember}/>,<Account details={viewMember}/>,<Subscription details={viewMember}/>];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    /// fix mobile ui
    <div className="w-full h-full bg-white flex flex-col">
      <Header
        action={setViewMember}
        value={null}
        title={"View Member"}
        subtitle={"View member account information"}
      />
      <div className="w-full h-full flex flex-col p-3 ">
        <div className="bg-zinc-100 w-full mt-3 ">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="settings tabs"
          >
            <Tab label="Overview" sx={{ textTransform: "none", color: grey }} />
            <Tab label="Account" sx={{ textTransform: "none", color: grey }} />
            <Tab
              label="Subscription"
              sx={{ textTransform: "none", color: grey }}
            />
          </Tabs>
        </div>
        <div className="w-full h-[90%] overflow-auto">
        {
          tabs[value]
        }
      </div>
      </div>
    </div>
  );
};

export default ViewMember;

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineFileImage,
} from "react-icons/ai";
import app from "../../../utils/app";
import axios from "axios";
import Loading from "./loading";
import { useStateContext } from "../../../utils/contextprovider";
import dayjs from "dayjs";
import Cookies from "js-cookie";

const PublishArticle = ({ postAction, reload }) => {
  const token = Cookies.get("token_highlander_admin");
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [story, setStory] = useState("");
  const [tag, setTag] = useState("");
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const hiddenFileInput = useRef(null);
  const { userAdmin } = useStateContext();
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;

  const handleDragImages = (result) => {
    //console.log(result);
    if (result.destination !== null) {
      const items = images;

      const [reorderedItem] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderedItem);

      setImages(items);
    }
  };

  console.log(userAdmin);

  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);

    setImages([...images, ...arr]);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const uploadArticle = async () => {
    if (title === "" || story === "" || tag === "") {
      setMsg("Please fill in");
      handleClickSnack();
      return;
    }
    setUploading(true);
    var promises = [];
    const urls = [];
    const storage = getStorage(app);
    images.forEach(async (image, index) => {
      const ext = image.name.split(".")[1];
      var storageref = ref(
        storage,
        "news/" + Date.now() + "_" + index + "." + ext
      );
      const uploadTask = uploadBytesResumable(storageref, image.file);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          setMsg("Upload failed try again");
          setUploading(false);
          handleClickSnack();
          return;
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadUrl) => {
              return downloadUrl;
            }
          );
          console.log("here " + url);
          urls.push(url);
        }
      );
    });
    Promise.all(promises)
      .then((uploadTaskSnapshotsArray) => {
        const promises = [];
        uploadTaskSnapshotsArray.forEach(async (uploadTaskSnap) => {
          promises.push(
            getDownloadURL(uploadTaskSnap.ref).then((downloadUrl) => {
              return downloadUrl;
            })
          );
        });
        return Promise.all(promises);
      })
      .then(async (urlsArray) => {
        console.log(urlsArray);
        ///Upload the product
        var dt = dayjs(new Date());
        var published =
          dt.$y +
          "-" +
          (dt.$M + 1).toString().padStart(2, "0") +
          "-" +
          dt.$D.toString().padStart(2, "0") +
          " " +
          dt.$H +
          ":" +
          dt.$m.toString().padStart(2, "0") +
          " +00:00";

        const article = {
          images: urlsArray,
          title: title,
          story: story,
          published: published,
          tag: tag,
          posted_by: userAdmin.admin_id,
        };

        axios
          .post(apiUrl + "create_news", article, {
            headers: {
              "API-Key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            reload(1);
            setMsg("Article uploaded");
            setUploading(false);
            handleClickSnack();
          })
          .catch((error) => {
            console.log(error);
            setMsg("Failed to upload article");
            setUploading(false);
            handleClickSnack();
          });
        /// upload the article
      });
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMsg("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };
  return (
    <div className="w-full h-full flex flex-col gap-3 bg-white p-5">
      <p className="text-sm">Select images</p>
      <div className="w-full h-[35vh] border p-3 overflow-auto flex flex-col place-content-center place-items-center gap-1">
        <div
          onClick={handleClick}
          className="w-full h-[5rem] border border-dashed flex place-content-center place-items-center gap-2 cursor-pointer"
        >
          <AiOutlineFileImage />
          <p className="text-xs">Click to add images</p>
        </div>
        <input
          hidden
          accept="image/*"
          type="file"
          ref={hiddenFileInput}
          onChange={uploadHandler()}
          multiple
        />
        <DragDropContext onDragEnd={handleDragImages}>
          <Droppable droppableId="droppable-3" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={
                  images.length !== 0
                    ? "w-full h-[40vh] grid grid-cols-2 sm:grid-cols-8 gap-3 overflow-auto"
                    : "w-full h-0"
                }
              >
                {provided.placeholder}
                {images.map((file, index) => (
                  <Draggable
                    key={index + "images"}
                    draggableId={`${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        {...provided.placeholder}
                        className="w-full h-[20vh] border relative cursor-pointer group flex flex-shrink-0"
                      >
                        <img
                          src={file.item}
                          alt="Cannot Load"
                          className="w-full h-full"
                        />
                        <div className=" w-8 h-8 rounded-full border bg-white shadow-2xl flex place-content-center place-items-center text-xs absolute top-3 left-3">
                          {index + 1}
                        </div>

                        <div className="absolute bottom-0 w-full h-0 duration-700 group-hover:h-20 bg-slate-400/75 flex place-content-center place-items-center gap-2">
                          <div className="cursor-pointer w-7 h-7 bg-white rounded-full border shadow-xl group-hover:flex hidden place-content-center place-items-center">
                            <AiOutlineEye />
                          </div>
                          <div
                            onClick={() => {
                              setImages((oldArray) => {
                                return oldArray.filter(
                                  (value, i) => i !== index
                                );
                              });
                            }}
                            className="cursor-pointer w-7 h-7 bg-white rounded-full border shadow-xl group-hover:flex hidden place-content-center place-items-center"
                          >
                            <AiOutlineDelete />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-title" size="small">
          Title
        </InputLabel>
        <OutlinedInput
          id="user-title"
          label="Title"
          variant="outlined"
          size="small"
          fullWidth
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          sx={{
            borderRadius: 0,
            height: "40px",
          }}
        />
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-tag" size="small">
          Tag
        </InputLabel>
        <OutlinedInput
          id="user-tag"
          label="Tag"
          variant="outlined"
          size="small"
          fullWidth
          value={tag}
          onChange={(event) => {
            setTag(event.target.value);
          }}
          sx={{
            borderRadius: 0,
            height: "40px",
          }}
        />
      </FormControl>
      <FormControl variant="outlined" fullWidth size="small">
        <InputLabel htmlFor="outlined-adornment-story" size="small">
          Story
        </InputLabel>
        <OutlinedInput
          multiline
          rows={6}
          id="user-story"
          label="Story"
          variant="outlined"
          size="small"
          fullWidth
          value={story}
          onChange={(event) => {
            setStory(event.target.value);
          }}
          sx={{
            borderRadius: 0,
          }}
        />
      </FormControl>

      <div className="w-full flex place-content-end gap-2">
        <div className="w-[8rem]">
          <Button
            fullWidth
            sx={useStyle.Button}
            onClick={() => {
              uploadArticle();
            }}
          >
            Publish
          </Button>
        </div>
        <div className="w-[8rem]">
          <Button
            fullWidth
            variant="outlined"
            sx={useStyle.OutlineButton}
            onClick={() => {
              postAction(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />

      {uploading && (
        <div className="flex place-content-center place-items-center w-full h-full top-0 left-0 absolute bg-slate-500/50">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default PublishArticle;

import React, { useState } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { useStateContext } from "../../utils/contextprovider";
import memberLinks from "../../utils/member_links";
import TopBarMember from "./components/topbar";
import { IconButton } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";

const MainMembers = () => {
  const { memberNav, setMemberNav } = useStateContext();
  const [toogleMenu, setToogleMenu] = useState(false);
  const token = Cookies.get("token_member_highlander");
  if (!token) {
    return <Navigate to="/" />;
  }

  //console.log(user);

  const selected =
    "flex h-10 w-full gap-5 hover:bg-slate-300 hover:text-slate-700 cursor-pointer duration-300 place-items-center p-3 bg-slate-500 text-zinc-300";
  const unselected =
    "flex h-10 w-full gap-5 hover:bg-slate-300 hover:text-slate-700  cursor-pointer duration-300 place-items-center p-3 text-white";

  return (
    <div className="flex  h-screen w-full">
      <aside className="w-[11.5rem] h-screen border-r bg-[#161415] shadow-xl hidden sm:flex flex-col flex-shrink-0">
        <div className="h-12" />
        {memberLinks.map((value, index) => (
          <Link
            key={index}
            to={value.link}
            onClick={() => {
              setMemberNav(value.title);
            }}
          >
            <div className={memberNav === value.title ? selected : unselected}>
              {value.icons}
              <p className="text-sm font-light">{value.title}</p>
            </div>
          </Link>
        ))}
      </aside>
      <div
        className={
          toogleMenu
            ? "w-full h-screen absolute top-0 left-0 z-[99999]"
            : "hidden"
        }
        onClick={() => {
          setToogleMenu(!toogleMenu);
        }}
      >
        <aside
          className={
            toogleMenu
              ? "w-[11.5rem] h-screen border-r bg-[#161415] shadow-xl flex sm:hidden flex-col flex-shrink-0 absolute top-0 left-0 z-[9999999999]"
              : "hidden"
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="h-12 w-full flex place-content-end">
            <IconButton
              onClick={() => {
                setToogleMenu(!toogleMenu);
              }}
            >
              <AiOutlineClose color="#FFF" />
            </IconButton>
          </div>
          {memberLinks.map((value, index) => (
            <Link
              key={index}
              to={value.link}
              onClick={() => {
                setMemberNav(value.title);
              }}
            >
              <div
                className={memberNav === value.title ? selected : unselected}
              >
                {value.icons}
                <p className="text-sm font-light">{value.title}</p>
              </div>
            </Link>
          ))}
        </aside>
      </div>

      <div className="flex flex-grow bg-zinc-50 flex-col">
        <div className="w-full h-[4rem] flex-shrink-0 border border-b-1 ">
          {" "}
          <TopBarMember toogle={setToogleMenu} toogleValue={toogleMenu} />{" "}
        </div>
        <div className="flex flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainMembers;

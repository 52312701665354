import React from "react";
import Header from "./header";
import { months } from "../../../utils/months";
import ImgCarousel from "./img_carousel";
import { IconButton } from "@mui/material";
import { MdArrowBack } from "react-icons/md";

const ViewNews = ({ setViewStory, viewStory }) => {
  //console.log(viewStory);
  return (
    <div
      className="w-full h-full place-items-center  overflow-auto font-helvetica flex flex-col  "
      style={{ scrollbarWidth: "thin" }}
    >
      <div className="w-full flex place-items-center">
        <IconButton size="small" onClick={() => { setViewStory(false)}}>
          <MdArrowBack/>
        </IconButton>

      </div>
      <div
        className="w-full sm:px-72 sm:h-[83vh] h-[81vh] gap-2 flex flex-col overflow-auto "
        style={{ scrollbarWidth: "thin" }}
      >
        
        <div className="w-full h-[15rem] sm:h-[25rem] flex flex-shrink-0 ">
          <ImgCarousel images={viewStory.images} />
        </div>
        <div className="w-full flex place-content-between">
          <p className="text-[11px] font-thin text-red-500">{viewStory.tag}</p>
          <div className="flex gap-2">
          <p className="text-[11px] font-thin text-slate-500">By</p>
          <p className="text-[11px] font-thin text-slate-500">{viewStory.fullname}</p>
          </div>
        </div>

        <p className="text-xl font-semibold">{viewStory.title}</p>

        <div className="  flex gap-2">
          <p className="text-xs text-slate-500">Published</p>
          <p className="text-xs text-slate-500">{`${
            months[new Date(viewStory.posted).getUTCMonth()]
          } ${new Date(viewStory.posted).getDate()}, ${new Date(
            viewStory.posted
          ).getUTCFullYear()}`}</p>
        </div>
        <p className="text-xs text-slate-500 mt-3 mb-10">{viewStory.story}</p>
      </div>
    </div>
  );
};

export default ViewNews;

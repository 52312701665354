import React from "react";
import { act } from "react-dom/test-utils";

const DialogBackground = ({ action, value }) => {
  return (
    <div
      className="w-full h-full bg-slate-500/50"
      onClick={() => {
        action(value);
      }}
    />
    
  );
};

export default DialogBackground;

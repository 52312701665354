import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Tooltip,
} from "@mui/material";
import app from "../../../utils/app";
import React, { useEffect, useRef, useState } from "react";
import { BiBell, BiExit, BiLock } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import Cookies from "js-cookie";
import {
  MdClose,
  MdMenu,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { useStateContext } from "../../../utils/contextprovider";
import axios from "axios";
import DialogBackground from "./dialog_background";
import { AiOutlineClose } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { AnimatePresence, motion } from "framer-motion";

const TopBarAdmin = ({ toogle, toogleValue }) => {
  const [userMenu, setUserMenu] = useState(false);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const { userAdmin } = useStateContext();
  const [logout, setLogout] = useState(false);
  const apiUrl = process.env.REACT_APP_API;
  const [loadingNotification, setLoadingNotification] = useState(true);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  const [passwordMenu, setPasswordMenu] = useState(false);
  const [account, setAccount] = useState(false);
  const [password, setPassword] = useState("");
  const [showpass, setShowPass] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [pending, setPending] = useState(0);
  const [msg, setMsg] = useState(null);
  const apiKey = process.env.REACT_APP_KEY;
  const [editImage, setEditImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const token = Cookies.get("token_highlander_admin");
  const [img, setImg] = useState(null);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    getNotifications();
    // console.log(userAdmin);
  }, []);

  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);
    setImg(arr[0]);
  };

  const getNotifications = () => {
    axios
      .post(
        apiUrl + "initial_notifications",
        {
          id: userAdmin.admin_id,
          role: "admin",
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setLoadingNotification(false);
        // console.log(result.data.data);
        setNotifications(result.data.data);
        var count = 0;
        for (let i = 0; i < result.data.data.length; i++) {
          if (!result.data.data[i].viewed_by.includes(userAdmin.admin_id)) {
            count = count + 1;
          }
        }
        //console.log(count)
        setPending(count);
      })
      .catch((error) => {
        //update error here
        setLoadingNotification(false);
        setErrorNotification(true);
      });
  };

  const handleClickShowPassword = () => {
    setShowPass(!showpass);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const uploadImage = () => {
    if (img === null) {
      setErrorMsg("Please select image to upload");
      handleClickSnack();
      return;
    }
    const storage = getStorage(app);
    const ext = img.name.split(".")[1];
    var storageref = ref(
      storage,
      "profile_img/" + Date.now() + "_" + userAdmin.fullname + "." + ext
    );
    const uploadTask = uploadBytesResumable(storageref, img.file);
    setUploading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
        setErrorMsg("Upload failed try again");
        setUploading(false);
        handleClickSnack();
        return;
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          var detail = {
            img: downloadUrl,
            id_number: userAdmin.admin_id,
          };

          axios
            .post(apiUrl + "update_profile", detail, {
              headers: {
                "API-Key": apiKey,
                Authorization: `Bearer ${token}`,
              },
            })
            .then((result) => {
              userAdmin["profile"] = downloadUrl;
              localStorage.setItem("USER_ADMIN", JSON.stringify(userAdmin));
              setErrorMsg("Image uploaded");
              setUploading(false);
              handleClickSnack();
            })
            .catch((error) => {
              //console.log(error);
              setErrorMsg("Failed to upload image");
              setUploading(false);
              handleClickSnack();
            });
        });
      }
    );
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  const updateNotification = (uid, id, index) => {
    // console.log("here");
    axios
      .post(
        apiUrl + "viewed_update",
        {
          user_id: uid,
          id: id,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        var val = [...notifications];
        var arr = [...val[index].viewed_by, uid];

        val[index].viewed_by = arr;

        setNotifications(val);
        setPending(pending - 1);
      })
      .catch((error) => {
        setErrorMsg("Failed to upate please try again");
        handleClickSnack();
      });
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorMsg("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const updatePassword = () => {
    if (password === "") {
      setErrorMsg("Failed please fill in new password");
      handleClickSnack();
      return;
    }

    setUpdatingPassword(true);

    axios
      .post(
        apiUrl + "update_password",
        {
          new_password: password,
          user_id: userAdmin.admin_id,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setUpdatingPassword(false);
        setErrorMsg("Password Changed");
        handleClickSnack();
      })
      .catch((error) => {
        setUpdatingPassword(false);
        setErrorMsg("Failed please try again");
        handleClickSnack();
      });
  };

  return (
    <div className="h-full w-full flex place-items-center place-content-between pl-3 pr-3 font-helvetica">
      <div className="flex gap-2 place-items-center">
        <div className="sm:hidden">
          <IconButton
            onClick={() => {
              toogle(!toogleValue);
            }}
          >
            <MdMenu />
          </IconButton>
        </div>
        <p className="text-sm text-slate-500">Welcome Admin</p>
      </div>
      <div className="flex gap-1 place-content-center place-items-center">
        <div
          className="w-9 h-9 border hover:bg-slate-200 border-[#94A3B8] rounded flex place-content-center place-items-center cursor-pointer"
          onClick={() => {
            setNotificationMenu(true);
            //console.log(userAdmin)
          }}
        >
          <Badge badgeContent={pending} color="error">
            <BiBell />
          </Badge>
        </div>

        <div
          className="rounded border border-[rgb(148,163,184)] p-1 gap-1 flex place-content-center place-items-center cursor-pointer h-9 max-w-24 sm:max-w-40"
          onClick={() => {
            setUserMenu(true);
          }}
        >
          <Tooltip title={userAdmin.fullname}>
            <div>
              <Avatar
                variant="rounded"
                src={userAdmin.profile === null ? "" : userAdmin.profile}
                sizes="small"
                sx={{ height: "27px", width: "27px" }}
              />
            </div>
          </Tooltip>
          <p className="pr-2 text-xs line-clamp-1">{userAdmin.fullname}</p>
        </div>
      </div>

      {userMenu && (
        <div className="w-full h-full z-[999] absolute top-0 left-0">
          <div
            className="w-full h-full"
            onClick={() => {
              setUserMenu(false);
            }}
          />
          <div className="absolute top-12 right-5 bg-white rounded-md shadow-lg z-20 w-[17rem] h-[14rem] flex flex-col gap-1 place-items-center py-5">
            <div className="flex gap-3 pl-4 w-full place-content-start place-items-center mt-2">
              <div>
                <Avatar
                  variant="rounded"
                  src={userAdmin.profile === null ? "" : userAdmin.profile}
                  sizes="small"
                  sx={{ height: "40px", width: "40px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    setPopUp(true);
                  }}
                />
                {popup && (
                  <div className="w-full h-full z-[9999999] absolute top-0 left-0 flex place-content-center place-items-center">
                    <div
                      className="w-full h-full fixed top-0 left-0 z-[99999]"
                      onClick={() => {
                        setPopUp(false);
                      }}
                    />

                    <div className="w-36 border flex bg-white flex-col shadow-2xl absolute z-[9999999] right-20 top-16">
                      <div
                        className="w-full h-10 border-b text-center cursor-pointer flex place-content-center place-items-center hover:bg-zinc-100"
                        onClick={() => {
                          setPopUp(false);
                          setUserMenu(false);
                          setEditImage(true);
                        }}
                      >
                        {" "}
                        <p className="text-xs">Change Image</p>{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col ">
                <div className=" flex place-content-center place-items-center gap-1 text-sm text-black font-medium">
                  <p> {userAdmin.fullname}</p>
                </div>

                <p className="text-xs text-slate-400">Admin</p>
              </div>
            </div>

            <div className="w-full border-t h-1 mt-2" />
            <div className="flex flex-col w-full font-medium text-black place-items-center">
              <div
                className="w-[98%] rounded-sm h-10  pl-3 hover:bg-slate-200 flex place-start-center place-items-center gap-3 p-3 cursor-pointer"
                onClick={() => {
                  setUserMenu(false);
                  setAccount(true);
                }}
              >
                <BsPerson size={20} />

                <p className="text-sm ">Account</p>
              </div>
              <div
                className="w-[98%] rounded-sm h-10  pl-3 hover:bg-slate-200 flex place-start-center place-items-center gap-3 p-3 cursor-pointer"
                onClick={() => {
                  setUserMenu(false);
                  setPasswordMenu(true);
                }}
              >
                <BiLock size={20} />

                <p className="text-sm ">Edit Password</p>
              </div>
              <div
                className="w-[98%] rounded-sm h-10  pl-3 hover:bg-slate-200 flex place-start-center place-items-center gap-3 p-3 cursor-pointer"
                onClick={() => {
                  setUserMenu(false);
                  setLogout(true);
                }}
              >
                <BiExit size={20} />
                <p className="text-sm ">Logout</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {logout && (
          <div className="absolute w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setLogout(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[25%] w-[95%] h-[25%] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div className="w-full h-full bg-white absolute flex flex-col place-content-center place-items-center gap-10">
                <div className="flex flex-col place-content-center place-items-center gap-3">
                  <p className="text-lg text-black font-medium">Logout?</p>
                  <p className="text-xs text-slate-400">Exit your account</p>
                </div>

                <div className="flex w-full px-3 place-content-center">
                  <div className="sm:w-3/4 w-full gap-2 flex">
                    <Button
                      fullWidth
                      sx={useStyle.OutlineButton}
                      onClick={() => {
                        setLogout(false);
                      }}
                      variant="outlined"
                    >
                      <p>Cancel</p>
                    </Button>
                    <Button
                      fullWidth
                      sx={useStyle.Button}
                      onClick={() => {
                        Cookies.remove("token_highlander_admin");
                        window.location.reload();
                      }}
                    >
                      <p className="text-white">Yes</p>
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {notificationMenu && (
        <div className="w-full h-full z-[99999]  flex place-content-center absolute top-0 left-0">
          <div
            className="w-full h-full"
            onClick={() => {
              setNotificationMenu(false);
            }}
          />
          <div className="absolute top-12 sm:right-10 bg-white rounded-md shadow-lg z-20 w-[22rem] h-[25rem">
            {errorNotification ? (
              <div className="w-full h-full flex place-content-center place-items-center">
                <p
                  className="text-xs cursor-pointer"
                  onClick={() => {
                    setErrorNotification(false);
                    setLoadingNotification(true);
                    getNotifications();
                  }}
                >
                  Error loading try again
                </p>
              </div>
            ) : loadingNotification ? (
              <div className="w-full h-full flex place-content-center place-items-center">
                <CircularProgress sx={{ color: "black" }} size={15} />
              </div>
            ) : notifications.length === 0 ? (
              <div className="w-full h-64 flex place-content-center place-items-center">
                <p className="text-xs">No Notifications</p>
              </div>
            ) : (
              <div className="flex flex-col w-full h-full overflow-auto">
                <div className="w-full flex place-content-between place-items-end">
                  <p className="text-sm p-3">Notifications</p>
                </div>
                <div
                  className="border-t w-full h-64 overflow-auto"
                  style={{ scrollbarWidth: "thin" }}
                >
                  {notifications.map((value, index) => (
                    <div
                      key={"notification" + index}
                      className="w-full h-16 gap-3 border-b flex place-items-center p-3 cursor-pointer hover:bg-slate-100"
                      onClick={() => {
                        if (!value.viewed_by.includes(userAdmin.admin_id)) {
                          updateNotification(
                            userAdmin.admin_id,
                            value.id,
                            index
                          );
                        }
                        setNotificationMenu(false);
                        setMsg(value);
                      }}
                    >
                      <div className="w-8 h-8 rounded-sm border-slate-400 border flex place-content-center place-items-center relative">
                        <BiBell />
                        <div
                          className={
                            value.viewed_by.includes(userAdmin.admin_id)
                              ? "hidden"
                              : "absolute bg-red-500 w-2 h-2 rounded-full left-0 top-0"
                          }
                        />
                      </div>

                      <div className="flex flex-col">
                        <p className="text-xs text-slate-400">{value.title}</p>
                        <p className="text-[10px] font-light line-clamp-1">
                          {value.message}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {msg && (
        <div className="w-full h-full absolute z-[999999999] top-0 left-0 flex place-content-center place-items-center">
          <DialogBackground action={setMsg} value={null} />
          <div className="w-1/3 h-[60vh] bg-white rounded-sm absolute z-[99999]">
            <div className="p-3 border-b w-full h-14 flex place-content-between">
              <p className="text-sm text-slate-400">Notification</p>
              <div
                className="w-8 h-8 rounded-full border flex place-content-center place-items-center cursor-pointer hover:bg-slate-100"
                onClick={() => {
                  setMsg(null);
                }}
              >
                <MdClose />{" "}
              </div>
            </div>
            <div className="flex flex-col p-5 gap-3">
              <div className="flex gap-3 text-sm">
                <p className="text-slate-400">Title:</p>
                <p>{msg.title}</p>
              </div>

              <p className="text-sm">{msg.message}</p>
            </div>
          </div>
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {account && (
          <div className="absolute w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setAccount(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute w-[95%] sm:w-[30%] h-[40vh] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className="w-full h-full bg-white rounded-sm absolute z-[99999]
          flex flex-col place-content-center place-items-center gap-3"
              >
                <Avatar
                  variant="rounded"
                  src={userAdmin.profile === null ? "" : userAdmin.profile}
                  sizes="small"
                  sx={{ height: "103px", width: "103px" }}
                />
                <div className="flex flex-col gap-1 place-content-center place-items-center">
                  <p className="text-sm">{userAdmin.fullname}</p>
                  <p className="text-xs">{userAdmin.email}</p>
                </div>
                <p className="text-sm">Admin</p>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {passwordMenu && (
          <div className="absolute w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setPasswordMenu(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[30%] w-[95%] h-[40vh] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className=" w-full h-full bg-white rounded-sm absolute z-[99999]
          flex flex-col place-content-center p-3 gap-7"
              >
                <div className="flex flex-col place-items-center">
                  <p className="text-lg text-black font-medium">
                    Reset Password ?
                  </p>
                  <p className="text-xs text-slate-400">
                    Fill in a new password to change your password
                  </p>
                </div>
                <FormControl variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    size="small"
                  >
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      borderRadius: 0,
                      height: "40px",
                    }}
                    fullWidth
                    size="small"
                    id="outlined-adornment-password"
                    label="New Password"
                    type={showpass ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showpass ? (
                            <MdOutlineVisibilityOff size={15} />
                          ) : (
                            <MdOutlineVisibility size={15} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </FormControl>

                <div className="flex place-content-end gap-2">
                  <div className="flex w-full gap-2">
                    <Button
                      fullWidth
                      onClick={() => {
                        updatePassword();
                      }}
                      sx={useStyle.Button}
                      endIcon={
                        updatingPassword ? (
                          <CircularProgress size={14} />
                        ) : (
                          <></>
                        )
                      }
                    >
                      Change Password
                    </Button>
                    <Button
                      fullWidth
                      sx={useStyle.OutlineButton}
                      variant="outlined"
                      onClick={() => {
                        setPasswordMenu(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {viewImage && (
        <div className="w-full h-full z-[9999999] absolute top-0 left-0 flex place-content-center place-items-center">
          <DialogBackground action={setViewImage} value={null} />

          <div className="w-1/2 h-1/2 bg-white absolute"></div>
        </div>
      )}
      {editImage && (
        <div className="w-full h-full absolute z-[999999999] top-0 left-0 flex place-content-center place-items-center">
          <DialogBackground action={setEditImage} value={false} />

          <div className=" w-[95%] sm:w-[30vw] pb-5 bg-white absolute flex flex-col place-items-center pt-10 gap-5">
            {img === null ? (
              <div className="w-full flex place-content-center my-3">
                <div
                  onClick={handleClick}
                  className="w-20 h-20 rounded-full border border-black border-dashed flex place-content-center place-items-center gap-2 cursor-pointer"
                >
                  <BsPerson />
                </div>
              </div>
            ) : (
              <div className="w-full flex  place-content-center">
                <div
                  className="w-20 h-20 border rounded-full relative cursor-pointer my-3"
                  onClick={handleClick}
                >
                  <img
                    src={img.item}
                    alt="Cannot Load"
                    className="w-full h-full rounded-full"
                  />

                  <div
                    className="w-8 h-8 bg-slate-200 absolute right-1 bottom-0 rounded-full cursor-pointer flex place-content-center place-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImg(null);
                    }}
                  >
                    {" "}
                    <IoClose size={13} />{" "}
                  </div>
                </div>
              </div>
            )}

            <input
              hidden
              accept="image/*"
              type="file"
              ref={hiddenFileInput}
              onChange={uploadHandler()}
            />

            <div className="w-1/3 flex place-content-center">
              <Button
                sx={useStyle.Button}
                onClick={() => {
                  uploadImage();
                }}
                endIcon={uploading ? <CircularProgress size={14} /> : <></>}
                fullWidth
              >
                Upload Image
              </Button>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMsg}
        action={action}
      />
    </div>
  );
};

export default TopBarAdmin;

import {IconButton, Pagination } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { IoReload } from 'react-icons/io5';
import Loading from '../admin/components/loading';
import ErrorTryAgain from '../admin/components/error_try_agin';
import SuchEmpty from '../admin/components/empty';
import { months } from '../../utils/months';
import NewsCard from '../admin/components/news_card';
import ViewNews from '../admin/components/view_news';
import Cookies from 'js-cookie';
const NewsMember = () => {
 
  const apiUrl = process.env.REACT_APP_API;
  const token = Cookies.get("token_member_highlander");
  const [news, setNews] = useState([]);
  const [page,setPage] = useState(1);
  const [pages,setPages] = useState(1);
  const [viewStory, setViewStory] = useState();
  const [loadingNews, setLoadingNews] = useState(true);
  const [newsError, setNewsError] = useState(false);
  const apiKey = process.env.REACT_APP_KEY;

  useEffect(() => {
    getNews(1)
  }, [])

  /// Handle pagination request
  const handleChange = (event, value) => {
    //console.log(value);
    setPage(value);
    setLoadingNews(true);
    //console.log(value)
      getNews(value);
    
  };

  const getNews = (val) => {

    axios
      .post(apiUrl + "news", {
        page: val,
      }, {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        }
      })
      .then((result) => {
        //console.log(result.data)
        setPages(Math.ceil(result.data.total_news / 4));
        setNews(result.data.data);
        setLoadingNews(false);
      })
      .catch((error) => {
        setLoadingNews(false);
        setNewsError(true);
      });
  }

  const reload = () => {
    setLoadingNews(true);
    getNews(1)
    setPage(1)
  }
  return (
    <div className='w-full h-full  flex flex-col font-helvetica relative'>

     
      <div className="w-full border-b h-16 p-3 flex flex-col place-content-center">
        <p className="font-medium text-sm">News</p>
        <p className="text-xs text-slate-400">
          Get all news from the club
        </p>
    
      </div>
      <div className='w-full h-[82vh] flex sm:flex-row flex-col'>
      {
        loadingNews ? <div className='w-full h-full flex place-content-center place-items-center'>
          <Loading/>
        </div> : newsError ? <div className='flex place-content-center place-items-center w-full'>
          <ErrorTryAgain action={() => {reload()}}/> </div> : news.length ===0 ? <div className='flex place-content-center place-items-center w-full h-full'>
          <SuchEmpty action={() => {reload()}} showButton={true}/>
        </div> : <><div className='sm:w-1/2 w-full flex-shrink-0 h-1/3 sm:h-full bg-cover hover:shadow-lg  p-3 bg-slate-400 bg-center bg-no-repeat cursor-pointer flex flex-col place-items-start place-content-end gap-3' style={{backgroundImage: "url(" + news[0].images[0] +")"}} onClick={() => {setViewStory(news[0])}}>
        <div className='p-2 rounded-sm sm:flex hidden flex-grow-0 place-content-center place-items-center bg-slate-700/90'>
         <p className='uppercase text-white text-xs'>
         {news[0].tag}
          </p>
         </div>
         <div className='w-[90%]'>
         <p className='text-2xl font-semibold text-white line-clamp-3'>{news[0].title}</p>
         </div>
  
         <div className='flex gap-2 text-xs text-white'>
          <p>{news[0].fullname}</p>
          <p className={news[0].fullname === null ? 'hidden' : 'flex' }>|</p>
          <p>{`${months[new Date(news[0].posted).getUTCMonth()] } ${new Date(
                  news[0].posted
                ).getDate()}, ${new Date(news[0].posted).getUTCFullYear()}`}</p>
         </div>
        </div>
        <div className='flex flex-col w-full h-full gap-3 '>
        <div className='w-full h-[53vh] sm:h-[75vh] overflow-auto flex flex-col p-2' style={{scrollbarWidth: "thin" }}>
        {news.map((value, index) => (
                <div
                  onClick={() => {
                    setViewStory(value);
                  }}
                >
                  <NewsCard key={index + "news"} details={value}></NewsCard>{" "}
                </div>
              ))}
         
        </div>
        <Pagination page={page} count={pages} onChange={handleChange}/>
        </div></>
      }
      </div>

      {viewStory && (
        <div className="w-full h-full bg-white  absolute top-0 left-0 flex flex-col place-items-center">
          <div className="w-full flex place-content-start p-3">
            <ViewNews setViewStory={setViewStory} viewStory={viewStory} />
          </div>
        </div>
      )}

      
    </div>
  )
}

export default NewsMember
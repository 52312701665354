import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import app from "../../../utils/app";
import axios from "axios";
import { BsPerson } from "react-icons/bs";
import { useStateContext } from "../../../utils/contextprovider";
import Cookies from 'js-cookie';

const CreateUser = () => {
  const token = Cookies.get('token_highlander_admin');
  const [password, setPassword] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [username, setUsername] = useState("");
  const [fullname, setFullname] = useState("");
  const [createMember, setCreateMember] = useState(true);
  const [createAdmin, setCreateAdmin] = useState(true);
  const [deleteAdmin, setDeleteAdmin] = useState(true);
  const [editAdmin, setEditAdmin] = useState(true);
  const [createMemberAccount, setCreateMemberAccount] = useState(true);
  const [sendUpdates, setSendUpdates] = useState(true);
  const [sendNotifications, setSendNotifications] = useState(true);
  const [publishNews, setPublishNews] = useState(true);
  const [deleteMember, setDeleteMember] = useState(true);
  const [editMember, setEditMember] = useState(true);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const hiddenFileInput = useRef(null);
  const [showpass, setShowPass] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [img, setImg] = useState(null);
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const { userAdmin } = useStateContext();

  const handleClickShowPassword = () => {
    setShowPass(!showpass);
  };

  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);
    setImg(arr[0]);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const createUser = () => {

   

    if (
      password === "" ||
      idNumber === "" ||
      username === "" ||
      fullname === "" ||
      email === ""
    ) {
      setMessage("Please fill in the details");
      handleClickSnack();
      return;
    }
    setUploading(true);
    if (img !== null) {
      const storage = getStorage(app);
      const ext = img.name.split(".")[1];
      var storageref = ref(
        storage,
        "profiles/" + Date.now() + "_" + fullname + "." + ext
      );
      const uploadTask = uploadBytesResumable(storageref, img.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          setMessage("Upload failed try again");
          setUploading(false);
          handleClickSnack();
          return;
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            var details = {
              id_number: idNumber,
              username: username,
              password: password,
              fullname: fullname,
              role: "admin",
              profile_img: downloadUrl,
              email: email,
              permissions: {
                create_member: createMember,
                create_member_account: createMemberAccount,
                delete_member: deleteMember,
                edit_member: editMember,
                create_admin: createAdmin,
                delete_admin: deleteAdmin,
                edit_admin: editAdmin,
                publish_article: publishNews,
                send_notifications: sendNotifications,
                send_updates: sendUpdates
              }
            };

            axios
              .post(apiUrl + "create_admin", details, {
                headers: {
                  "API-Key": apiKey,
                  "Authorization": `Bearer ${token}`,
                }
              })
              .then((result) => {
                setMessage("User uploaded");
                setUploading(false);
                handleClickSnack();
              })
              .catch((error) => {
                console.log(error);
                setMessage("Failed to upload image");
                setUploading(false);
                handleClickSnack();
              });
          });
        }
      );
    } else {
      var details = {
        id_number: idNumber,
        username: username,
        password: password,
        fullname: fullname,
        role: "admin",
        profile_img: "",
        email: email,
        permissions: {
          create_member: createMember,
          create_member_account: createMemberAccount,
          delete_member: deleteMember,
          edit_member: editMember,
          create_admin: createAdmin,
          delete_admin: deleteAdmin,
          edit_admin: editAdmin,
          publish_article: publishNews,
          send_notifications: sendNotifications,
          send_updates: sendUpdates
        }
      };

      axios
        .post(apiUrl + "create_admin", details, {
          headers: {
            "API-Key": apiKey,
            "Authorization": `Bearer ${token}`,
          }
        })
        .then((result) => {
          setMessage("User Uploaded");
          setUploading(false);
          handleClickSnack();
        })
        .catch((error) => {
          console.log(error);
          setMessage("Failed to upload image");
          setUploading(false);
          handleClickSnack();
        });
    }
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="w-full  h-full relative" >
      <div className="overflow-auto flex flex-col place-items-center flex-grow-0 max-h-[70vh] pb-14 " style={{scrollbarWidth: "thin"}}>
      <div className="sm:w-[50%] w-full flex flex-col gap-3 p-3">
        {img === null ? (
          <div className="w-full flex place-content-center my-3">
            <div
              onClick={handleClick}
              className="w-20 h-20 rounded border border-black border-dashed flex place-content-center place-items-center gap-2 cursor-pointer"
            >
              <BsPerson />
            </div>
          </div>
        ) : (
          <div className="w-full flex place-content-center">
            <div
              className="w-20 h-20 border rounded-full relative cursor-pointer my-3"
              onClick={handleClick}
            >
              <img
                src={img.item}
                alt="Cannot Load"
                className="w-full h-full rounded-full"
              />

              <div
                className="w-8 h-8 bg-slate-200 absolute right-1 bottom-0 rounded-full cursor-pointer flex place-content-center place-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  setImg(null);
                }}
              >
                {" "}
                <IoClose size={13} />{" "}
              </div>
            </div>
          </div>
        )}

        <input
          hidden
          accept="image/*"
          type="file"
          ref={hiddenFileInput}
          onChange={uploadHandler()}
        />
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-idnumber" size="small">
            Id Number
          </InputLabel>
          <OutlinedInput
            sx={{
              borderRadius: 0,
              height: "40px",
            }}
            fullWidth
            size="small"
            id="outlined-adornment-idnumber"
            label="Id Number"
            value={idNumber}
            onChange={(event) => {
              setIdNumber(event.target.value);
            }}
          />
        </FormControl>
        <div className="w-full gap-2 flex">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-username" size="small">
              Username
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: 0,
                height: "40px",
              }}
              fullWidth
              size="small"
              id="outlined-adornment-username"
              label="Username"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-fullname" size="small">
              Full name
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: 0,
                height: "40px",
              }}
              fullWidth
              size="small"
              id="outlined-adornment-fullname"
              label="Fullname"
              value={fullname}
              onChange={(event) => {
                setFullname(event.target.value);
              }}
            />
          </FormControl>
        </div>
        <div className="flex gap-2 w-full ">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-email" size="small">
              Email
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: 0,
                height: "40px",
              }}
              fullWidth
              size="small"
              id="outlined-adornment-email"
              label="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password" size="small">
              Password
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: 0,
                height: "40px",
              }}
              fullWidth
              size="small"
              id="outlined-adornment-password"
              label="New Password"
              type={showpass ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showpass ? (
                      <MdOutlineVisibilityOff size={15} />
                    ) : (
                      <MdOutlineVisibility size={15} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </FormControl>
        </div>
        <div className="bg-zinc-100 w-full flex place-items-center p-3 ">
          <p className="text-sm">Permissions</p>
        </div>

        <div className="w-full border border-slate-400 h-14 flex place-items-center p-3 place-content-between">
          <p className="text-xs sm:text-sm"> Members</p>
          <div className="flex place-content-end place-items-center">
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={createMember}
                  onChange={() => {
                    setCreateMember(!createMember);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Create Member</span>}
            />
            <FormControlLabel
              control={
                <Switch
                size="small"
                  checked={createMemberAccount}
                  onChange={() => {
                    setCreateMemberAccount(!createMemberAccount);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Create Account</span>}
            />
            <FormControlLabel
              control={
                <Switch
                size="small"
                  checked={deleteMember}
                  onChange={() => {
                    setDeleteMember(!deleteMember);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Delete</span>}
            />
            <FormControlLabel
              control={
                <Switch
                size="small"
                  checked={editMember}
                  onChange={() => {
                    setEditMember(!editMember);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Edit</span>}
            />
          </div>
        </div>
        <div className="w-full border border-slate-400 h-14 flex place-items-center p-3 place-content-between">
          <p className="text-xs sm:text-sm">Admin</p>
          <div className="flex place-content-end place-items-center">
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={createAdmin}
                  onChange={() => {
                    setCreateAdmin(!createAdmin);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Create Admin</span>}
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={editAdmin}
                  onChange={() => {
                    setEditAdmin(!editAdmin);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Edit Admin</span>}
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={deleteAdmin}
                  onChange={() => {
                    setDeleteAdmin(!deleteAdmin);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Delete Admin</span>}
            />
            
          </div>
        </div>
        <div className="w-full border border-slate-400 h-14 flex place-items-center p-3 place-content-between">
          <p className="text-xs sm:text-sm">News</p>
          <div className="flex place-content-end place-items-center">
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={publishNews}
                  onChange={() => {
                    setPublishNews(!publishNews);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Publish news</span>}
            />
        
            
          </div>
        </div>
        <div className="w-full border border-slate-400 h-14 flex place-items-center p-3 place-content-between">
          <p className="text-xs sm:text-sm">Alerts</p>
          <div className="flex place-content-end place-items-center">
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={sendNotifications}
                  onChange={() => {
                    setSendNotifications(!sendNotifications);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Send Notifications</span>}
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={sendUpdates}
                  onChange={() => {
                    setSendUpdates(!sendUpdates);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              labelPlacement="top"
              label={<span className="text-[10px] sm:text-sm line-clamp-1">Send Updates</span>}
            />
        
            
          </div>
        </div>
     
        </div>
       {!userAdmin.create_admin ? <></> : <div className="flex gap-3 place-content-end place-items-center absolute bottom-0 w-full right-0  border-t h-14 border-slate-300 bg-white shadow-lg px-3">
          {!uploading ? (
            <Button
              sx={useStyle.Button}
              onClick={() => {
                createUser();
              }}
            >
              Create User
            </Button>
          ) : (
            <CircularProgress size={13} />
          )}
          <Button sx={useStyle.Button}>Clear</Button>
        </div>}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
          action={action}
        />

      
      </div>
    </div>
  );
};

export default CreateUser;

import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useStateContext } from "../../../../utils/contextprovider";
import axios from "axios";
import Cookies from 'js-cookie';

const Account = ({ details }) => {
  const token = Cookies.get('token_highlander_admin');
  const [username, setUsername] = useState(details.membership_id);
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { userAdmin } = useStateContext();
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
    },
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMsg("");
  };

  const createAccount = () => {
    if (password === "" || username === "") {
      setMsg("Please fill in the details");
      handleClickSnack();
      return;
    }
    setLoading(true);

    axios
      .post(apiUrl + "create_user", {
        id_number: details.id_number,
        role: "member",
        password: password,
        username: username,
        profile_img: ""
      }, {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        }
      })
      .then((result) => {
        setLoading(false);
        setMsg("User created");
        handleClickSnack(); 
      })
      .catch((error) => {
        console.log("here");
        setLoading(false);
        setMsg("Failed try again");
        handleClickSnack();
      });
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="w-full h-full flex place-content-center place-items-center">
      { !userAdmin.create_member_account ? <div className="w-full h-full flex place-content-center place-items-center">
          <p className="text-sm text-zinc-400">You do not have permissions</p>
        </div> : details.username !== null ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          <p className="text-sm text-zinc-400">User account already created</p>
        </div>
      ) : (
        <div className="w-full sm:w-1/2 h-[70%] border">
          <div className="flex place-items-center p-3 w-full h-16 border-b">
            <p className="text-sm text-slate-400">Create Account</p>
          </div>

          <div className="flex flex-col w-full h-full place-content-center place-items-center">
            <div className="gap-3 w-[90%] h-full pt-10 sm:w-3/4 flex flex-col">
              <div className="flex gap-3  mb-5">
                <p className="text-slate-400 text-sm">Fullname: </p>
                <p className="text-sm">{details.fullname}</p>
              </div>
              <FormControl variant="outlined" fullWidth disabled>
                <InputLabel
                  htmlFor="outlined-adornment-member-username"
                  size="small"
                >
                  Username
                </InputLabel>
                <OutlinedInput
                  id="user-member-username"
                  label="Username"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                  sx={{
                    borderRadius: 0,
                    height: "40px",
                  }}
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  htmlFor="outlined-adornment-member-password"
                  size="small"
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="user-member-password"
                  label="Password"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  sx={{
                    borderRadius: 0,
                    height: "40px",
                  }}
                />
              </FormControl>
              <div className="h-4" />
              <Button
                variant="contained"
                fullWidth
                sx={useStyle.Button}
                onClick={() => {
                  createAccount();
                }}
                endIcon={
                  loading ? (
                    <CircularProgress sx={{ color: "white" }} size={15} />
                  ) : (
                    <></>
                  )
                }
              >
                Create Account
              </Button>
            </div>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
    </div>
  );
};

export default Account;

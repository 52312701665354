import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Snackbar,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useStateContext } from "../../../utils/contextprovider";
import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import ViewMember from "../components/view_member";
import AddMember from "../components/add_member";
import DialogBackground from "../components/dialog_background";
import Loading from "../components/loading";
import ErrorTryAgain from "../components/error_try_agin";
import SuchEmpty from "../components/empty";
import { IoReload, IoWarning } from "react-icons/io5";
import CreateAccount from "../components/create_account";
import { AiOutlineClose } from "react-icons/ai";
import {
  MdDeleteOutline,
  MdOutlinePerson,
  MdOutlineShield,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { months } from "../../../utils/months";
import { GrDocument } from "react-icons/gr";
import Report from "../components/report";
import { FaCheckCircle } from "react-icons/fa";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const Members = () => {
  const token = Cookies.get("token_highlander_admin");
  const [members, setMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [errorLoadingMembers, setErrorLoadingMembers] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [addMember, setAddMember] = useState(false);
  const [viewMember, setViewMember] = useState();
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [name, setName] = useState("");
  const [id_number, setIdNumber] = useState("");
  const [memberId, setMemberId] = useState("");
  const [index, setIndex] = useState(0);
  const [createAccount, setCreateAccount] = useState(false);
  const [password, setPassword] = useState("");
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [showpass, setShowPassword] = useState(false);
  const [generateReport, setGenerateReport] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(null);
  const [editPassword, setEditPassword] = useState(null);
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const { userAdmin } = useStateContext();

  /// Initial loading
  useEffect(() => {
    getMembers(1, 10);
  }, []);

  /// Handle pagination request
  const handleChange = (event, value) => {
    // console.log(value);
    setPage(value);
    setLoadingMembers(true);
    if (!search) {
      getMembers(value, itemsPerPage);
    } else {
      searchMembers(value, itemsPerPage);
    }
  };

  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  ///get Paginated members per page
  const getMembers = (val, itemsVal) => {
    axios
      .post(
        apiUrl + "members",
        {
          items: itemsVal,
          page: val,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        //console.log(result);

        setMembers(result.data.data);
        //console.log(result.data);
        setPages(Math.ceil(result.data.member_total / itemsVal));
        setLoadingMembers(false);
      })
      .catch((error) => {
        setLoadingMembers(false);
        setErrorLoadingMembers(true);
      });
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMsg("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const searchMembers = (val, itemsVal) => {
    if (query === "") {
      setMsg("Fill in search before searching ....");
      handleClickSnack();
      return;
    }

    setSearch(true);
    setLoadingMembers(true);
    axios
      .post(
        apiUrl + "search_members",
        {
          q: query,
          items: itemsVal,
          page: val,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        //console.log(result);
        setMembers(result.data.data);
        //console.log(result.data);
        setPages(Math.ceil(result.data.member_total / itemsVal));
        setLoadingMembers(false);
      })
      .catch((error) => {
        console.log(error)
        setLoadingMembers(false);
        setErrorLoadingMembers(true);
      });
  };

  /// changes to items per page
  const handleChangeMenu = (e) => {
    setItemsPerPage(e.target.value);
    if (search) {
      searchMembers(1, e.target.value);
    } else {
      getMembers(1, e.target.value);
    }
    setPage(1);
  };

  const deleteAcc = (id) => {
    //console.log(id);
    axios
      .post(
        apiUrl + "delete_member",
        {
          id_number: id,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setDeleteAccount(null);
        setMsg("Member deleted");
        handleClickSnack();
        getMembers(1, itemsPerPage);
      })
      .catch((error) => {
        setMsg("Failed to delete member try again");
        handleClickSnack();
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showpass);
  };

  const updatePassword = () => {
    if (password === "") {
      setMsg("Failed please fill in new password");
      handleClickSnack();
      return;
    }

    setUpdatingPassword(true);

    axios
      .post(
        apiUrl + "update_password",
        {
          new_password: password,
          user_id: editPassword.id_number,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setUpdatingPassword(false);
        setMsg("Password Changed");
        handleClickSnack();
      })
      .catch((error) => {
        setUpdatingPassword(false);
        setMsg("Failed please try again");
        handleClickSnack();
      });
  };

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="w-full h-14 border-slate-300 flex place-content-between place-items-center sm:px-5">
        <div className="p-3 flex flex-col place-content-center">
          <p className="font-medium text-sm">Members</p>
          <p className="text-xs text-slate-500">Manage club members</p>
        </div>

        <div className="flex gap-2 w-[10rem] ">
          {!userAdmin.create_member ? (
            <></>
          ) : (
            <Button
              fullWidth
              onClick={() => {
                setAddMember(true);
              }}
              sx={useStyle.Button}
            >
              Add Member
            </Button>
          )}
        </div>
      </div>

      <div className="w-screen sm:w-full h-16 border px-3 sm:px-5 border-b border-slate-400 flex place-content-between place-items-center bg-zinc-100">
        <div className="flex place-items-center gap-1">
          {" "}
          <div className="h-10 w-[90%] sm:w-96">
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                htmlFor="outlined-adornment-member-search"
                size="small"
                sx={{ zIndex: 0 }}
              >
                Search ...
              </InputLabel>
              <OutlinedInput
                id="user-member-search"
                label="Search ..."
                variant="outlined"
                size="small"
                fullWidth
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                sx={{
                  borderRadius: 1,
                  borderColor: "#94A3B8",
                  height: "40px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setPage(1);
                        searchMembers(1, itemsPerPage);
                      }}
                      edge="end"
                    >
                      <FaMagnifyingGlass size={13} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <Tooltip title="Report">
            <div
              className="w-10 h-10 flex place-content-center place-items-center rounded border border-slate-400 hover:bg-slate-200 cursor-pointer "
              onClick={() => {
                setGenerateReport(true);
              }}
            >
              <GrDocument size={14} className="text-slate-500" />
            </div>
          </Tooltip>
          <Tooltip title="Reload">
            <div
              className="w-10 h-10 flex place-content-center place-items-center rounded border border-slate-400 hover:bg-slate-200 cursor-pointer "
              onClick={() => {
                getMembers(1, 10);
                setItemsPerPage(10)
              }}
            >
              <IoReload size={14} className="text-slate-500" />
            </div>
          </Tooltip>
          {search ? (
            <Tooltip title="Clear">
              <div
                className="w-10 h-10 flex place-content-center place-items-center rounded border border-slate-400 hover:bg-slate-200 cursor-pointer "
                onClick={() => {
                  setSearch(false);
                  setErrorLoadingMembers(false);
                  getMembers(1, itemsPerPage);
                  setQuery("");
                }}
              >
                <AiOutlineClose size={15} />
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        className="sm:w-full w-[100vw]  max-h-[68vh] "
        style={{
          scrollbarWidth: "thin",
        }}
      >
        {errorLoadingMembers ? (
          <div className="w-full h-[78vh] flex place-content-center place-items-center">
            <ErrorTryAgain
              action={() => {
                if (search) {
                  searchMembers(page, itemsPerPage);
                } else {
                  getMembers(page, itemsPerPage);
                }

                setErrorLoadingMembers(false);
              }}
            />
          </div>
        ) : !loadingMembers && members.length === 0 ? (
          <SuchEmpty
            showButton={true}
            action={() => {
              setLoadingMembers(true);
              if (search) {
                searchMembers(1, 10);
              } else {
                getMembers(1, 10);
              }
            }}
          />
        ) : (
          <div
            className="w-full h-[68vh] overflow-y-auto "
            style={{ scrollbarWidth: "thin" }}
          >
            <table className={members.length === 0 ? "hidden" : "w-full"}>
              <tr className="border-b border-slate-300 h-8  text-xs">
              <th>
                  <p className="line-clamp-1 text-start ml-3 cursor-pointer text-xs font-medium">
                    Status
                  </p>
                </th>
                <th className=" h-10  border-slate-300 min-w-32 max-h-10 ">
                  <p className="text-xs font-medium text-start ml-3">
                    First Name
                  </p>
                </th>
                <th className=" h-10  border-slate-300 min-w-28 max-h-10 ">
                  <p className="text-xs font-medium text-start ml-3">
                    ID Number
                  </p>
                </th>
                <th className=" h-10  border-slate-300 min-w-20 max-h-10 ">
                  <Tooltip title="Membership ID">
                    <p className="line-clamp-1 cursor-pointer text-xs font-medium text-start ml-3">
                      Membership ID
                    </p>
                  </Tooltip>
                </th>
                <th className=" h-10  border-slate-300 max-h-10 min-w-24">
                  <p className="text-xs font-medium text-start ml-3">DOB</p>
                </th>
                <th className=" h-10  border-slate-300 max-h-10 min-w-28">
                  <p className="text-xs font-medium text-start ml-3">Chapter</p>
                </th>
                <th className=" h-10  border-slate-300 max-h-10 min-w-16">
                  <Tooltip title="Subscription">
                    <p className="line-clamp-1 text-start ml-3 cursor-pointer text-xs font-medium">
                      Subscription
                    </p>
                  </Tooltip>
                </th>
                
                <th className=" h-10  border-slate-300 max-h-10 min-w-24">
                  <p className="text-xs font-medium text-start ml-3">Expiry</p>
                </th>
                <th className=" h-10  border-slate-300 max-h-10 min-w-24">
                  <p className="text-xs font-medium text-start ml-3">Joined</p>
                </th>
                <th className=" h-10  border-slate-300 max-h-10 min-w-28">
                  <p className="text-xs font-medium text-start ml-3">Address</p>
                </th>
                <th className=" h-10  border-slate-300 max-h-10 min-w-16">
                  <p className="text-xs font-medium text-start ml-3">Email</p>
                </th>
                <th className="h-10  border-slate-300 max-h-10 min-w-16">
                  <p className="text-xs font-medium text-start ml-3">Phone</p>
                </th>
                <th className=" border-slate-300 h-10 ">
                  <p className="text-xs font-medium text-start ml-3">Actions</p>
                </th>
              </tr>

              {members.map((value, index) => (
                <tr
                  key={index + "member"}
                  className={
                    index % 2 === 0
                      ? "w-full h-10 border-b border-slate-300 text-center cursor-pointer hover:bg-slate-300"
                      : "w-full h-10 border-b border-slate-300 text-center cursor-pointer hover:bg-slate-300 bg-slate-100"
                  }
                  onClick={() => {
                    setViewMember(value);
                  }}
                >
                   <td className=" h-10 pl-3  border-slate-300 flex place-content-center place-items-center ">
                    <div
                      className={
                        new Date(value.expiry) < new Date()
                          ? "bg-red-300/50 rounded p-1 gap-1 flex place-content-center place-items-center"
                          : "bg-green-300/50 rounded p-1 gap-1 flex place-content-center place-items-center"
                      }
                    >
                      {" "}
                      {new Date(value.expiry) < new Date() ? (
                        <p className="text-xs">
                          <IoWarning color="red" />
                        </p>
                      ) : (
                        <p className="text-xs">
                          <FaCheckCircle color="green" />
                        </p>
                      )}{" "}
                      {new Date(value.expiry) < new Date() ? (
                        <p className="text-xs text-black">Expired</p>
                      ) : (
                        <p className="text-xs text-black">Active</p>
                      )}{" "}
                    </div>
                  </td>
                  <td className=" border-slate-300  p-3">
                    <Tooltip title={value.fullname}>
                      <p className="line-clamp-1 cursor-pointer text-start text-xs">
                        {value.fullname}
                      </p>
                    </Tooltip>
                  </td>
                  <td className=" border-slate-300  p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs">
                      {value.id_number}
                    </p>
                  </td>
                  <td className=" border-slate-300  p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs">
                      {value.membership_id}
                    </p>
                  </td>
                  <td className=" border-slate-300  p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs">
                      {value.dob === null
                        ? "-"
                        : `${
                            months[new Date(value.dob).getUTCMonth()]
                          } ${new Date(value.dob).getDate()}, ${new Date(
                            value.dob
                          )
                            .getUTCFullYear()
                            .toString()
                            .substring(2)}`}{" "}
                    </p>
                  </td>
                  <td className=" border-slate-300  p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs">
                      {value.chapter}
                    </p>
                  </td>
                  <td className=" border-slate-300 pl-3">
                    <div
                      className={
                        value.sub === "LIFETIME"
                          ? "p-1 rounded flex place-content-center place-items-center bg-purple-300/50 gap-1"
                          : "p-1 rounded flex place-content-center place-items-center bg-zinc-300/50 gap-1"
                      }
                    >
                      <p className="text-black text-xs capitalize">
                        {value.sub.toString().toLowerCase()}
                      </p>
                    </div>
                  </td>
                 
                  <td className=" border-slate-300  p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs">
                      {`${months[
                        new Date(value.expiry).getUTCMonth()
                      ].substring(0, 3)} ${new Date(
                        value.expiry
                      ).getDate()}, ${new Date(value.expiry)
                        .getUTCFullYear()
                        .toString()
                        .substring(2)}`}
                    </p>
                  </td>
                  <td className=" border-slate-300 p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs ">
                      {`${
                        months[new Date(value.joined).getUTCMonth()]
                      } ${new Date(value.joined).getDate()}, ${new Date(
                        value.joined
                      )
                        .getUTCFullYear()
                        .toString()
                        .substring(2)}`}
                    </p>
                  </td>
                  <td className=" border-slate-300  p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs">
                      {value.address}
                    </p>
                  </td>
                  <td className=" border-slate-300  p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs">
                      {value.email === null ? "-" : value.email}
                    </p>
                  </td>
                  <td className=" border-slate-300 p-3">
                    <p className="line-clamp-1 cursor-pointer text-start text-xs ">
                      {value.mobile === null ? "-" : value.mobile}
                    </p>
                  </td>
                  <td className=" h-10 border-slate-300 text-xs flex w-full place-content-between min-w-20">
                    {!userAdmin.create_member_account ? (
                      <></>
                    ) : (
                      <Tooltip
                        title={
                          value.username === null && value.role === null
                            ? "User account not active"
                            : "Member has an active account"
                        }
                      >
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              value.username === null &&
                              value.role === null
                            ) {
                              setIndex(index);
                              setIdNumber(value.id_number);
                              setCreateAccount(true);
                              setName(value.fullname);
                              setMemberId(value.membership_id)
                            } else {
                              setMsg("Account already created");
                              handleClickSnack();
                            }
                          }}
                          className="flex flex-shrink-0"
                          size="small"
                        >
                          <MdOutlinePerson
                            size={16}
                            className="cursor"
                            style={{
                              color:
                                value.username === null && value.role === null
                                  ? "#f00"
                                  : "#0f0",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!userAdmin.edit_member ? (
                      <></>
                    ) : (
                      <Tooltip title="Account credentials">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              value.username !== null &&
                              value.role !== null
                            ) {
                              setEditPassword(value);
                            } else {
                              setMsg("No account found");
                              handleClickSnack();
                            }
                          }}
                          className="flex flex-shrink-0"
                          size="small"
                        >
                          <MdOutlineShield
                            size={16}
                            className="cursor text-black"
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {!userAdmin.delete_member ? (
                      <></>
                    ) : (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteAccount(value);
                        }}
                        size="small"
                        className="flex flex-shrink-0"
                      >
                        <MdDeleteOutline
                          size={16}
                          className="cursor text-black"
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>

      <div
        className={
          errorLoadingMembers ||
          (!loadingMembers && members.length === 0) ||
          members.length === 0
            ? "hidden"
            : "w-full h-12 border-t flex place-items-center gap-2 p-2 pt-4"
        }
      >
        <div className="w-20">
          <FormControl size="small" fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              sx={{ zIndex: 0 }}
            >
              Per Page
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={itemsPerPage}
              label="Per Page"
              onChange={handleChangeMenu}
              size="small"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Pagination page={page} count={pages} onChange={handleChange} />
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {addMember && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setAddMember(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[45%] w-[95%] h-[95%] flex flex-col place-content-center place-items-center z-[9999]"
              aria-label="Account"
            >
              <AddMember setAddMember={setAddMember} />
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {loadingMembers && (
        <div className="w-full h-full absolute top-0 left-0 z-[999] flex place-content-center place-items-center ">
          <DialogBackground action={setLoadingMembers} value={false} />
          <Loading />
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {createAccount && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setCreateAccount(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute w-[95%] sm:w-[40%] h-[80%] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <CreateAccount
                close={setCreateAccount}
                id_number={id_number}
                members={members}
                index={index}
                name={name}
                member_id={memberId}
              />
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {deleteAccount && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setDeleteAccount(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[25%] w-[95%] h-[25%] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div className="w-full h-full bg-white absolute flex flex-col place-content-center place-items-center gap-10">
                <div className="flex flex-col place-content-center place-items-center gap-3">
                  <p className="text-lg text-black font-medium">
                    Delete Account?
                  </p>
                  <p className="text-xs text-slate-400">
                    Once account is deleted it cannot be recovered
                  </p>
                </div>

                <div className="flex w-full px-3 place-content-center">
                  <div className="sm:w-3/4 w-full gap-2 flex">
                    <Button
                      fullWidth
                      sx={useStyle.OutlineButton}
                      onClick={() => {
                        setDeleteAccount(null);
                      }}
                      variant="outlined"
                    >
                      <p>Cancel</p>
                    </Button>
                    <Button
                      fullWidth
                      sx={useStyle.Button}
                      onClick={() => {
                        deleteAcc(deleteAccount.id_number);
                      }}
                    >
                      <p className="text-white">Yes</p>
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {viewMember && (
        <div className="w-full h-full absolute top-0 left-0 z-[999] flex flex-col ">
          <ViewMember viewMember={viewMember} setViewMember={setViewMember} />
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {editPassword && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setEditPassword(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[30%] w-[95%] h-[40vh] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className="w-full h-full bg-white rounded-sm absolute z-[99999]
          flex flex-col place-content-center p-3 gap-7"
              >
                <div className="flex flex-col place-items-center">
                  <p className="text-lg text-black font-medium">
                    Reset Password ?
                  </p>
                  <p className="text-xs text-slate-400">
                    Fill in a new password to change your password
                  </p>
                </div>
                <FormControl variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    size="small"
                  >
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      borderRadius: 0,
                      height: "40px",
                    }}
                    fullWidth
                    size="small"
                    id="outlined-adornment-password"
                    label="New Password"
                    type={showpass ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showpass ? (
                            <MdOutlineVisibilityOff size={15} />
                          ) : (
                            <MdOutlineVisibility size={15} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </FormControl>

                <div className="flex place-content-center gap-5">
                  <Button
                    fullWidth
                    onClick={() => {
                      updatePassword();
                    }}
                    sx={useStyle.Button}
                  >
                    <div className="flex gap-2 place-items-center">
                      {updatingPassword ? (
                        <CircularProgress size={14} />
                      ) : (
                        <></>
                      )}
                      <p className="text-white">Change Password</p>
                    </div>
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {generateReport && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setGenerateReport(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[40%] w-[95%] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <Report closeReport={setGenerateReport} />
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
    </div>
  );
};

export default Members;

import { createContext, useContext, useState } from "react";


/// Application state
/// Get and update the user

const StateContext = createContext();

export const ContextProvider = ({ children }) => {

    /// User initial set to null
  const [user, _setUser] = useState(JSON.parse(localStorage.getItem("USER")));
  const [userAdmin, _setUserAdmin] = useState(JSON.parse(localStorage.getItem("USER_ADMIN")));
  ///Track which current page are we on
  const [adminNav,setAdminNav] = useState("Home")
  const [memberNav, setMemberNav] = useState("Home")


  const setUser = (user) => {
    _setUser(user);

    if (user != null) {
      localStorage.setItem("USER", JSON.stringify(user));
    } else {
      localStorage.removeItem("USER");
    }
  };

  const setUserAdmin = (user) => {
    _setUserAdmin(user);

    if (user != null) {
      localStorage.setItem("USER_ADMIN", JSON.stringify(user));
    } else {
      localStorage.removeItem("USER_ADMIN");
    }
  };
  return (
    <StateContext.Provider
      value={{
        user,
        setUser,
        userAdmin,
        setUserAdmin,
        adminNav,
        setAdminNav,
        memberNav,
        setMemberNav
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);

import React, { useEffect, useState } from "react";
import NewsCard from "../components/news_card";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import ViewNews from "../components/view_news";
import { Link } from "react-router-dom";
import { useStateContext } from "../../../utils/contextprovider";
import Loading from "../components/loading";
import ErrorTryAgain from "../components/error_try_agin";
import Cookies from 'js-cookie';

const HomeAdmin = () => {
  const [loadingNews, setLoadingNews] = useState(true);
  const [loadingStats, setLoadingStats] = useState(true);
  const [errorStats, setErrorStats] = useState(false);
  const { setAdminNav } = useStateContext();
  const [news, setNews] = useState();
  const [statsVal, setStats] = useState();
  const apiKey = process.env.REACT_APP_KEY;
  const [viewStory, setViewStory] = useState();
  const apiUrl = process.env.REACT_APP_API;
  const token = Cookies.get('token_highlander_admin');

  const frame =
    "<iframe src='https://www.scorebat.com/embed/competition/zimbabwe-premier-league/' frameborder='0' width='560' scrollbar-width: thin height='100%' allowfullscreen allow='autoplay; fullscreen' style='width:100%;overflow:hidden;' class='_scorebatEmbeddedPlayer_'></iframe><script>(function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = 'https://www.scorebat.com/embed/embed.js?v=arrv'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'scorebat-jssdk'));</script>";

  useEffect(() => {
    getNews();
    getStats();
  }, []);

  const getNews = () => {
    axios
      .get(apiUrl + "latest_news", {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((result) => {
        setNews(result.data.data);
        setLoadingNews(false);
      })
      .catch((error) => {
        console.log(error)
        setLoadingNews(false);
        setNews("Error try again later");
      });
  };
  const getStats = () => {
    axios
      .get(apiUrl + "statistics", {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoadingStats(false);
        setStats(result.data.data);
        //console.log(result.data.data);
      })
      .catch((error) => {
        setLoadingStats(false);
        setErrorStats(true);
      });
  };

  return (
    <div className="w-full h-full flex font-helvetica relative">
      <div
        className="w-full sm:w-2/3 h-[93vh] gap-3  flex flex-col overflow-auto border-r"
        style={{ scrollbarWidth: "thin" }}
      >
        <p className="font-medium text-base ml-3 mt-3">Members</p>
        {loadingStats ? (
          <div className="w-full h-52  flex flex-shrink-0 border-b place-content-center place-items-center gap-20 p-3 pt-5">
            <Loading />
          </div>
        ) : errorStats ? (
          <ErrorTryAgain
            action={() => {
              setLoadingStats(true);
              setErrorStats(false);
              getStats();
            }}
          />
        ) : (
          <div className="w-full h-32 flex flex-shrink-0 border-b place-content-between place-items-center p-1 sm:p-3 gap-1 sm:gap-2">
            <div className="flex w-1/4 flex-col gap-3 place-items-center sm:place-items-start  bg-white border border-slate-300 rounded-lg p-5 h-full">
              <p className="text-xs text-zinc-500 text-center flex">
                Active Members
              </p>
              <p className="font-medium text-base sm:text-2xl text-black">
                {statsVal[0].active}
              </p>
            </div>
            <div className="flex w-1/4 flex-col gap-3 place-items-center sm:place-items-start bg-white border border-slate-300 rounded-lg p-5 h-full">
              <p className="text-xs text-zinc-500 text-center flex">
                Life Members
              </p>

              <p className="text-lg sm:text-2xl font-medium text-black">
                {statsVal[0].lifetime}
              </p>
            </div>
            <div className="flex w-1/4 flex-col gap-3 place-items-center sm:place-items-start bg-white border border-slate-300 rounded-lg p-5 h-full">
              <p className="text-xs text-zinc-500 text-center flex">
                Subscription Members
              </p>

              <p className="text-lg sm:text-2xl font-medium text-black">
                {statsVal[0].ordinary}
              </p>
            </div>
            <div className="flex w-1/4 flex-col gap-3 place-items-center sm:place-items-start bg-white border border-slate-300 rounded-lg p-5 h-full">
              <p className="text-xs text-zinc-500 text-center flex">
                Total Members
              </p>

              <p className="text-lg sm:text-2xl font-medium text-black">
                {statsVal[0].total}
              </p>
            </div>
          </div>
        )}
        <div
          className="w-full sm:h-[65vh] h-[70vh] flex flex-col flex-shrink-0 p-3 sm:p-5 gap-3 overflow-hidden"
          style={{
            scrollbarWidth: "thin",
          }}
        >
          <p className="font-medium text-base">Fixtures</p>
          <div
            dangerouslySetInnerHTML={{ __html: frame ? frame : "" }}
            className="w-full h-full"
            style={{ scrollbarWidth: "thin" }}
          />
        </div>
      </div>
      <div className="w-1/3 h-full  hidden sm:flex flex-col p-5">
        <div className="w-full flex place-content-between  place-items-center">
          <p className="font-medium text-base">Latest club News</p>
          <Link
            to={"/admin/news"}
            onClick={() => {
              setAdminNav("News");
            }}
          >
            <p className="underline text-xs cursor-pointer">View all</p>
          </Link>
        </div>

        {loadingNews ? (
          <div className="w-full h-[82vh]  flex place-content-center place-items-center">
            {" "}
            <CircularProgress size={13} sx={{ color: "red" }} />{" "}
          </div>
        ) : (
          <div className="w-full h-[83vh]  flex flex-col overflow-auto mt-3">
            {news === "Error try again later" ? (
              <div className="w-full h-full flex place-content-center place-items-center">
                {" "}
                <p
                  className="text-xs cursor-pointer"
                  onClick={() => {
                    setLoadingNews(true);
                    getNews();
                  }}
                >
                  Error loading try again
                </p>{" "}
              </div>
            ) : (
              news.map((value, index) => (
                <div
                  onClick={() => {
                    setViewStory(value);
                  }}
                >
                  <NewsCard key={index + "news"} details={value}></NewsCard>{" "}
                </div>
              ))
            )}
          </div>
        )}
      </div>

      {viewStory && (
        <div className="w-full h-full bg-white  absolute top-0 left-0 flex flex-col place-items-center">
          <div className="w-full flex place-content-start p-3">
            <ViewNews setViewStory={setViewStory} viewStory={viewStory} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeAdmin;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Avatar,
  AvatarGroup,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Switch,
  Tooltip,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import Loading from "./loading";
import { IoIosNotificationsOutline } from "react-icons/io";
import DialogBackground from "./dialog_background";
import { useStateContext } from "../../../utils/contextprovider";
import { months } from "../../../utils/months";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const Notifications = () => {
  const token = Cookies.get("token_highlander_admin");
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [sendToAll, setSendToAll] = useState(false);
  const [viewMessage, setViewMessage] = useState(null);
  const [focused, setFocused] = useState(false);
  const [searchMember, setSearchMember] = useState("");
  const [title, setTitle] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [message, setMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const { userAdmin } = useStateContext();

  useEffect(() => {
    getNotifications();
  }, []);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.includes(" ")
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name.split(" ")[0][0]}`,
    };
  }

  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };
  const getNotifications = () => {
    /// get all notifications sent out
    axios
      .get(apiUrl + "get_all_notifications", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoading(false);
        setNotifications(result.data.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setMessage("Error loading please try again");
        handleClickSnack();
      });
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const searchForMembers = () => {
    axios
      .post(
        apiUrl + "search_members",
        {
          q: searchMember,
          items: 7,
          page: 1,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        //console.log(result);
        setSearchLoading(false);
        setSearchResults(result.data.data);
      })
      .catch((error) => {
        setSearchLoading(false);
        setSearchResults([]);
      });
  };

  const addMember = (val) => {
    if (!selectedMembers.includes(val)) {
      setSelectedMembers([...selectedMembers, val]);
    }
  };

  const postNotification = () => {
    if (messageBody === "") {
      setMessage("Please provide a message you want to send");
      handleClickSnack();
      return;
    }
    if (!sendToAll && selectedMembers.length === 0) {
      setMessage("Please select members to send to");
      handleClickSnack();
      return;
    }
    var sent_to = [];
    var names = [];

    if (sendToAll) {
      sent_to.push("All");
      names.push("All");
    } else {
      for (let i = 0; i < selectedMembers.length; i++) {
        sent_to.push(selectedMembers[i]["id_number"]);
        names.push(selectedMembers[i]["fullname"]);
      }
    }
    setSendingMessage(true);

    axios
      .post(
        apiUrl + "send_notifications",
        {
          title: title,
          message: messageBody,
          sent_by: userAdmin.id_number,
          viewed_by: [],
          message_type: "message",
          names: names,
          images: [],
          sent_to: sent_to,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setMessage("Notification sent");
        getNotifications();
        setTitle("");
        setMessageBody("");
        setSelectedMembers([]);
        setSendingMessage(false);
        handleClickSnack();
      })
      .catch((error) => {
        setMessage("Failed to send notification");
        setSendingMessage(false);
        handleClickSnack();
      });
  };

  return (
    <div className="w-full h-full flex flex-col gap-2">
      <div className="h-16 w-full flex p-5 place-content-end border-b">
        <div className="sm:w-[10vw]">
          <Button
            sx={useStyle.Button}
            fullWidth
            onClick={() => {
              setSendMessage(true);
            }}
          >
            Send Notification
          </Button>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          {" "}
          <Loading />{" "}
        </div>
      ) : error ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          <p
            className="text-xs cursor-pointer"
            onClick={() => {
              setLoading(true);
              setError(false);
              getNotifications();
            }}
          >
            Error loading tap to try again
          </p>{" "}
        </div>
      ) : notifications.length === 0 ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          {" "}
          <p className="text-xs">No Notifications</p>{" "}
        </div>
      ) : (
        <div className="flex flex-col w-full h-[65vh] overflow-auto p-3 gap-2">
          {notifications.map((value, index) => (
            <div
              key={index + "notification"}
              className="sm:w-1/2 w-full rounded h-20 border cursor-pointer border-slate-300 flex place-items-center p-5 gap-3 hover:bg-zinc-100"
              onClick={() => {
                setViewMessage(value);
              }}
            >
              <div className="w-10 h-10 rounded border border-slate-300 flex place-content-center place-items-center flex-shrink-0">
                <IoIosNotificationsOutline size={20} />{" "}
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm font-medium">{value.title}</p>
                <p className="text-xs text-slate-400 line-clamp-1">
                  {value.message}
                </p>
                <p className="text-[10px] text-slate-400 line-clamp-1">
                  {`${months[new Date(value.sent).getUTCMonth()]} ${new Date(
                    value.sent
                  ).getDate()}, ${new Date(value.sent).getUTCFullYear()}`}
                </p>
              </div>
            </div>
          ))}{" "}
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {viewMessage && (
          <div className="absolute w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setViewMessage(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[40%] w-[95%] h-[60vh] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className=" bg-white rounded-sm w-full h-full
          flex flex-col"
              >
                <div className="w-full h-24 border-b gap-5 flex place-items-start p-3">
                  <div className="w-14 h-14 rounded-lg border border-slate-300 flex place-content-center place-items-center flex-shrink-0">
                    <IoIosNotificationsOutline size={20} />{" "}
                  </div>
                  <div className="flex flex-col place-items-start w-full gap-1">
                    <div className="gap-2 flex place-items-baseline">
                      <p>{viewMessage.title}</p>{" "}
                      <p className="text-[12px] text-slate-400 line-clamp-1">
                        {`${
                          months[new Date(viewMessage.sent).getUTCMonth()]
                        } ${new Date(viewMessage.sent).getDate()}, ${new Date(
                          viewMessage.sent
                        ).getUTCFullYear()}`}
                      </p>
                    </div>

                    <div className="w-[35vw] gap-2 flex place-items-center ">
                      <p className="text-xs text-slate-400">To:</p>
                      <AvatarGroup
                        variant="rounded"
                        max={4}
                        sx={{
                          "& .MuiAvatar-root": {
                            height: "30px",
                            width: "30px",
                            fontSize: "8px",
                          },
                        }}
                      >
                        {viewMessage.names.map((value, index) => (
                          <Tooltip key={index + "names"} title={value}>
                            <Avatar
                              variant="rounded"
                              {...stringAvatar(value)}
                              sx={{
                                height: "30px",
                                width: "30px",
                                fontSize: "8px",
                              }}
                              className="cursor-pointer"
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </div>
                  </div>
                </div>

                <div className="w-full h-full p-3">
                  <p className="text-xs">{viewMessage.message}</p>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {sendMessage && (
          <div className="absolute w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setSendMessage(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[50%] w-[95%] h-2/3 flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className="w-full bg-white rounded-sm absolute z-[99999]
          flex flex-col place-content-start p-3 gap-3 pt-10  "
              >
                <div className="flex flex-col gap-1">
                  <p className="font-medium text-sm">Send Notifications</p>
                  <p className="text-xs text-slate-400">
                    Select members to send notifications
                  </p>
                </div>

                <div className="flex gap-1 w-full ">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      htmlFor="outlined-adornment-member"
                      size="small"
                    >
                      Search for member
                    </InputLabel>
                    <OutlinedInput
                      onFocus={() => {
                        setFocused(true);
                      }}
                      onKeyDown={() => {
                        if (searchMember !== "") {
                          setSearchLoading(true);
                          searchForMembers();
                        }
                      }}
                      onBlur={() => {
                        setTimeout(() => setFocused(false), 500);
                      }}
                      disabled={sendToAll}
                      sx={{
                        borderRadius: 0,
                        height: "40px",
                      }}
                      fullWidth
                      size="small"
                      id="outlined-adornment-member"
                      label="Search for member"
                      value={searchMember}
                      onChange={(event) => {
                        setSearchMember(event.target.value);
                      }}
                    />
                  </FormControl>
                  <Tooltip title="Send to all members">
                    <div className="w-[20%]">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sendToAll}
                            onChange={() => {
                              setSendToAll(!sendToAll);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        labelPlacement="start"
                        label={<span style={{ fontSize: "13px" }}>All</span>}
                      />
                    </div>
                  </Tooltip>
                </div>

                {!sendToAll && (
                  <div
                    className="w-full gap-3 h-16 flex flex-shrink-0 border p-3 place-items-center overflow-auto"
                    style={{
                      scrollbarWidth: "thin",
                    }}
                  >
                    {selectedMembers.length === 0 ? (
                      <p className="text-xs text-slate-400">MEMBERS</p>
                    ) : (
                      selectedMembers.map((value, index) => (
                        <Chip
                          key={"selectMember" + index}
                          avatar={
                            <Avatar
                              {...stringAvatar(value.fullname)}
                              src={value.member_img}
                              sx={{
                                height: "10px",
                                width: "10px",
                                fontSize: "8px",
                              }}
                            />
                          }
                          sx={{ fontSize: "10px" }}
                          label={value.fullname}
                          onDelete={() => {
                            setSelectedMembers([
                              ...selectedMembers.slice(0, index),
                              ...selectedMembers.slice(index + 1),
                            ]);
                          }}
                        />
                      ))
                    )}
                  </div>
                )}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-title" size="small">
                    Title
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      borderRadius: 0,
                    }}
                    fullWidth
                    size="small"
                    id="outlined-adornment-title"
                    label="Title"
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-message" size="small">
                    Message
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      borderRadius: 0,
                    }}
                    fullWidth
                    multiline
                    rows={7}
                    size="small"
                    id="outlined-adornment-message"
                    label="Message"
                    value={messageBody}
                    onChange={(event) => {
                      setMessageBody(event.target.value);
                    }}
                  />
                </FormControl>

                {focused && (
                  <div
                    className="w-[80%] absolute z-[999999] h-[70%] shadow-xl top-[25%] left-4 bg-white"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {searchLoading ? (
                      <div className="w-full h-full overflow-auto flex place-content-center place-items-center">
                        {" "}
                        <CircularProgress size={13} />{" "}
                      </div>
                    ) : searchResults.length === 0 ? (
                      <div className="w-full h-full flex place-content-center place-items-center">
                        {" "}
                        <p className="text-xs text-slate-400">
                          {" "}
                          No Members found
                        </p>{" "}
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2 p-3">
                        {searchResults.map((value, index) => (
                          <div
                            key={"memberResult" + index}
                            className="w-full h-12 flex  place-items-center place-content-between p-3 gap-2 hover:bg-zinc-100"
                          >
                            <div className="flex gap-1">
                              <Avatar
                                {...stringAvatar(value.fullname)}
                                src={value.member_img}
                                sx={{
                                  height: "30px",
                                  width: "30px",
                                  fontSize: "10px",
                                }}
                              />
                              <div className="flex flex-col gap-1">
                                <p className="text-sm">{value.fullname}</p>
                                <p className="text-xs text-zinc-400">
                                  {value.membership_id}
                                </p>
                              </div>
                            </div>

                            <Button
                              variant="outlined"
                              onClick={() => {
                                addMember(value);
                              }}
                              sx={{
                                textTransform: "none",
                                borderColor: "#000",
                                color: "#000",
                              }}
                            >
                              Add
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                <div className="w-full flex place-content-end mt-10 gap-2">
                  <Button
                    sx={useStyle.Button}
                    onClick={() => {
                      if (!sendingMessage) {
                        postNotification();
                      }
                    }}
                    endIcon={
                      sendingMessage ? <CircularProgress size={13} /> : <></>
                    }
                  >
                    Send Notification
                  </Button>
                  <Button
                    onClick={() => {
                      setSendMessage(false);
                    }}
                    sx={useStyle.OutlineButton}
                    variant="outlined"
                  >
                    Cancel
                  </Button>{" "}
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default Notifications;

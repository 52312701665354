import React, { useState } from "react";
import { months } from "../../../../utils/months";
import { Button, CircularProgress, IconButton, Snackbar } from "@mui/material";
import { useStateContext } from "../../../../utils/contextprovider";
import DialogBackground from "../dialog_background";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const Subscription = ({ details }) => {
  const { userAdmin } = useStateContext();
  const [expiryDate, setExpiryDate] = useState(dayjs(new Date(details.expiry)));
  const [editSubscription, setEditSubscription] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [lifetime, setLifetime] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const apiKey = process.env.REACT_APP_KEY;
  const apiUrl = process.env.REACT_APP_API;
  const token = Cookies.get("token_highlander_admin");

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };
  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };

  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const updateSubscription = () => {
    var dt =
      dayjs(new Date(details.expiry)).$y +
      dayjs(new Date(details.expiry)).$D +
      dayjs(new Date(details.expiry)).$M;

    if (dt === expiryDate.$y + expiryDate.$D + expiryDate.$M) {
      setMessage("Please select a new date");
      handleClickSnack();
      return;
    }
    setUpdating(true);
    console.log(expiryDate.$M);
    var expDt =
      expiryDate.$y +
      "-" +
      (expiryDate.$M + 1).toString().padStart(2, "0") +
      "-" +
      expiryDate.$D.toString().padStart(2, "0") +
      " " +
      "10:00" +
      ":" +
      expiryDate.$m.toString().padStart(2, "0") +
      "+00:00";

    //console.log(expDt);
    axios
      .post(
        apiUrl + "update_subscription",
        {
          id_number: details.id_number,
          subscription: expDt,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setUpdating(false);
        details["expiry"] = expDt;

        setMessage("Expiry date updated");
        handleClickSnack();
      })
      .catch((error) => {
        setUpdating(false);
        setMessage("Failed try again");
        handleClickSnack();
      });
  };

  const updateLifetime = () => {
    setUpdating(true);
    axios
      .post(
        apiUrl + "update_lifetime",
        {
          id_number: details.id_number,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setUpdating(false);
        setMessage("Member updated to lifetime");
        handleClickSnack();
      })
      .catch((error) => {
        setUpdating(false);
        setMessage("Failed try again");
        handleClickSnack();
      });
  };

  return (
    <div className="w-full h-full p-3">
      <div className="sm:w-1/2 w-full sm:h-[50%] h-[40%]  border border-zinc-200 p-5 flex flex-col gap-3 rounded-lg">
        <p className="text-sm text-black font-semibold">Subscriptions</p>
        <div className="flex w-full place-content-between">
          <p className="text-xs text-zinc-400">Joined</p>
          <p className="text-xs font-medium text-start">{`${
            months[new Date(details.joined).getUTCMonth()]
          } ${new Date(details.joined).getDate()}, ${new Date(
            details.joined
          ).getUTCFullYear()}`}</p>
        </div>
        <div className="flex w-full place-content-between">
          <p className="text-xs text-zinc-400">Subscription</p>
          <p className="text-xs font-medium text-start">{details.sub}</p>
        </div>
        <div className="flex w-full place-content-between">
          <p className="text-xs text-zinc-400">Expiry</p>
          <p className="text-xs font-medium text-start">{`${
            months[new Date(details.expiry).getUTCMonth()]
          } ${new Date(details.expiry).getDate()}, ${new Date(
            details.expiry
          ).getUTCFullYear()}`}</p>
        </div>

        {details.subscription !== 1 ? (
          <div className="mt-10 w-full flex gap-2">
            {userAdmin.edit_member ? (
              <Button
                sx={useStyle.Button}
                onClick={() => {
                  setEditSubscription(true);
                }}
              >
                Update Subscription
              </Button>
            ) : (
              <></>
            )}
            {userAdmin.edit_member ? (
              <Button
                sx={useStyle.OutlineButton}
                variant="outlined"
                onClick={() => {setLifetime(true)}}
              >
                Set Lifetime
              </Button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>

      {editSubscription && (
        <div className="w-full h-full flex place-content-center place-items-center absolute top-0 left-0">
          <DialogBackground action={setEditSubscription} value={false} />
          <div className="sm:w-[35%] w-[95%] h-[45%] bg-white absolute flex flex-col place-content-center place-items-center gap-10 p-3">
            <div className="flex flex-col place-items-center">
              <p className="text-lg text-black font-medium">
                Edit Subscription ?
              </p>
              <p className="text-xs text-slate-400">Change the expiry date</p>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expiry"
                sx={{ borderRadius: "0px", fontSize: "15px", width: "100%" }}
                value={expiryDate}
                onChange={(newValue) => setExpiryDate(newValue)}
              />
            </LocalizationProvider>

            <div className="w-full">
              <Button
                sx={useStyle.Button}
                onClick={() => {
                  if (!updating) {
                    updateSubscription();
                  }
                }}
                fullWidth
                endIcon={updating ? <CircularProgress size={12} /> : <></>}
              >
                Update Subscription
              </Button>
            </div>
          </div>
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {lifetime && (
          <div className="absolute w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setLifetime(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[30%] w-[95%] h-1/3 flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className="w-full h-full bg-white rounded-sm absolute z-[99999]
          flex flex-col place-content-center place-items-center p-3 gap-5 pt-10 "
              >
                <p className="text-lg">Set member to lifetime?</p>
                <div className="flex gap-2 w-full place-content-center place-items-center">
                  <Button variant="outlined" sx={useStyle.OutlineButton} onClick={() => {
                    setLifetime(false)
                  }}>Cancel</Button>
                  <Button onClick={() => {
                    updateLifetime()
                  }} sx={useStyle.Button} endIcon={updating ? <CircularProgress size={13}/> : <></>}>Update</Button>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default Subscription;

import { Button } from "@mui/material";
import React from "react";
import { BiError } from "react-icons/bi";

const ErrorTryAgain = ({ action }) => {
  return (
    <div className="flex flex-col place-items-center gap-5 mt-5">
        <BiError color="#f00" size={20}/>
      <p className="text-sm font-helvetica font-normal text-slate-400">Error loading please try again</p>
      <Button variant="contained" color="error" onClick={action}>
        Reload
      </Button>
    </div>
  );
};

export default ErrorTryAgain;

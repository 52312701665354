import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';

const CloseButton = ({close,value}) => {
  return (
    <div onClick={() => {close(value)}} className="w-8 h-8 rounded-full border flex place-content-center place-items-center cursor-pointer hover:bg-slate-200">
        <AiOutlineClose size={12} color='#505050' />
    </div>
  )
}

export default CloseButton
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useStyle } from "../../../utils/styles";
import { AnimatePresence, motion } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import Cookies from "js-cookie";
import app from "../../../utils/app";
import Loading from "../components/loading";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { MdPersonOutline } from "react-icons/md";
import ViewPlayer from "../components/view_player";

const Players = () => {
  dayjs.extend(utc);
  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [players, setPlayers] = useState([]);
  const [addPlayer, setAddPlayer] = useState(false);
  const [img, setImg] = useState(null);
  const [name, setName] = useState("");
  const [viewPlayer, setViewPlayer] = useState(null);
  const [position, setPosition] = useState("");
  const [alumni, setAlumni] = useState("");
  const [message, setMessage] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [open, setOpen] = useState(false);
  const [careerBeginning, setCareerBeginning] = useState("");
  const [number, setNumber] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [dobDate, setDobDate] = useState(dayjs(new Date()));
  const hiddenFileInput = useRef(null);
  const apiKey = process.env.REACT_APP_KEY;
  const apiUrl = process.env.REACT_APP_API;
  const token = Cookies.get("token_highlander_admin");

  useEffect(() => {
    getPlayers();
  }, []);

  const getPlayers = () => {
    axios
      .get(apiUrl + "players", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoading(false);
        setPlayers(result.data.data);
        //console.log(result)
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };
  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);
    setImg(arr[0]);
  };
  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };
  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const createPlayer = () => {
    if (name === "" || number === "" || position === "" || height === 0) {
      setMessage("Please fill in your details");
      handleClickSnack();
      return;
    }

    var dobDt =
      dobDate.$y +
      "-" +
      (dobDate.$M + 1).toString().padStart(2, "0") +
      "-" +
      dobDate.$D.toString().padStart(2, "0") +
      " " +
      dobDate.$H +
      ":" +
      dobDate.$m.toString().padStart(2, "0") +
      " +00:00";

    setPosting(true);
    //"hello".substring();
    /// Upload the image to storage bucket
    if (img === null) {
      axios
        .post(
          apiUrl + "create_player",
          {
            name: name,
            profile_img: "",
            position: position,
            number: number,
            birthday: dobDt,
            citizenship: citizenship,
            height: height,
            weight: weight,
            alumni: alumni,
            career: careerBeginning,
          },
          {
            headers: {
              "API-Key": apiKey,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            setMessage("Player created");
            handleClickSnack();
            getPlayers();
          } else {
            setMessage("Failed to create player try again");
            handleClickSnack();
          }
          setPosting(false);
        })
        .catch((error) => {
          setMessage("Error creating player try again");
          handleClickSnack();
          setPosting(false);
        });
    } else {
      /// upload image then get the url
      const storage = getStorage(app);
      const ext = img.name.split(".")[1];
      var storageref = ref(
        storage,
        "profiles/" + Date.now() + "_" + name + "." + ext
      );
      const uploadTask = uploadBytesResumable(storageref, img.file);
      //promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          setMessage("Error creating member try again");
          handleClickSnack();
          setPosting(false);
          return;
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            axios
              .post(
                apiUrl + "create_player",
                {
                  name: name,
                  profile_img: downloadUrl,
                  position: position,
                  number: number,
                  birthday: dobDt,
                  citizenship: citizenship,
                  height: height,
                  weight: weight,
                  alumni: alumni,
                  career: careerBeginning,
                },
                {
                  headers: {
                    "API-Key": apiKey,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((result) => {
                if (result.status === 200) {
                  setMessage("Player created");
                  handleClickSnack();
                  getPlayers();
                } else {
                  setMessage("Failed to create player try again");
                  handleClickSnack();
                }
                setPosting(false);
              })
              .catch((error) => {
                setMessage("Error creating player try again");
                handleClickSnack();
                setPosting(false);
              });
            return;
          });
        }
      );
    }
  };
  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="w-full h-14 border-b border-slate-300 flex place-content-between place-items-center sm:px-5">
        <div className="p-3 flex flex-col place-content-center">
          <p className="font-medium text-sm">Players</p>
          <p className="text-xs text-slate-500">
            Manage club player information
          </p>
        </div>
        <div className="flex gap-2 w-[10rem] ">
          <Button
            fullWidth
            onClick={() => {
              setAddPlayer(true);
            }}
            sx={useStyle.Button}
          >
            Add Player
          </Button>
        </div>
      </div>
      {loading ? (
        <div className="flex w-full h-full place-content-center place-items-center">
          <Loading />{" "}
        </div>
      ) : players.length === 0 ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          <p className="text-xs">No Players</p>{" "}
        </div>
      ) : (
        <div
          className="w-full h-[83vh] overflow-auto flex flex-wrap gap-2 p-3"
          style={{ scrollbarWidth: "thin" }}
        >
          {players.map((value, index) => (
            <div
              className=" w-full sm:w-[20%] h-80 sm:h-72 bg-white cursor-pointer shadow-2xl rounded-md flex flex-col flex-shrink-0"
              key={index + "item"}
              onClick={() => {
                setViewPlayer(value);
              }}
            >
              <div className="flex place-content-center place-items-center border rounded-t-md w-full h-[65%]">
                {value.profile_img === "" ? (
                  <MdPersonOutline size={40} />
                ) : (
                  <img
                    src={value.profile_img}
                    alt=""
                    className="w-full h-full"
                  />
                )}
              </div>
              <div className="flex flex-col w-full p-2 gap-2">
                <div className="w-full flex place-content-between">
                  <p className="text-xs text-slate-400">Name:</p>
                  <p className="text-xs capitalize">{value.name}</p>
                </div>
                <div className="w-full flex place-content-between">
                  <p className="text-xs text-slate-400">Number:</p>
                  <p className="text-xs">{value.number}</p>
                </div>
                <div className="w-full flex place-content-between">
                  <p className="text-xs text-slate-400">Position:</p>
                  <p className="text-xs capitalize">{value.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {addPlayer && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setAddPlayer(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="fixed sm:w-1/2 w-[95%] sm:h-[95%] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div className="h-full w-full py-5 bg-white rounded-lg z-[99] flex flex-col gap-3 px-3 place-items-center">
                <div className="w-full py-1 border-slate-300 border-b flex place-content-between place-items-end">
                  <div className="flex flex-col gap-3">
                    {img === null ? (
                      <div className="w-20 h-20 border rounded-md border-black border-dashed flex place-content-center place-items-center gap-2 cursor-pointer">
                        <BsPerson />
                      </div>
                    ) : (
                      <div
                        className="w-20 h-20 border rounded-md relative cursor-pointer"
                        onClick={handleClick}
                      >
                        <img
                          src={img.item}
                          alt="Cannot Load"
                          className="w-full h-full rounded-md"
                        />

                        <div
                          className="w-8 h-8 bg-slate-200 absolute right-1 bottom-0 rounded-md cursor-pointer flex place-content-center place-items-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            setImg(null);
                          }}
                        >
                          <IoClose size={13} />{" "}
                        </div>
                      </div>
                    )}
                    <div>
                      <p className="text-sm font-medium text-slate-600">
                        Profile picture
                      </p>
                      <p className="text-xs text-slate-400">
                        PNG, JPEG under 15MB
                      </p>
                    </div>
                  </div>
                  <Button
                    variant="outlined"
                    sx={useStyle.OutlineButton}
                    onClick={handleClick}
                  >
                    Upload picture
                  </Button>
                </div>

                <input
                  hidden
                  accept="image/*"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={uploadHandler()}
                />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-name" size="small">
                    Full Name
                  </InputLabel>
                  <OutlinedInput
                    id="user-name"
                    label="Full Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    sx={{
                      borderRadius: 0,
                      height: "40px",
                    }}
                  />
                </FormControl>
                <div className="flex gap-2 w-full">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-name" size="small">
                      Shirt number
                    </InputLabel>
                    <OutlinedInput
                      id="user-name"
                      label="Shirt number"
                      variant="outlined"
                      type="number"
                      size="small"
                      fullWidth
                      value={number}
                      onChange={(event) => {
                        setNumber(event.target.value);
                      }}
                      sx={{
                        borderRadius: 0,
                        height: "40px",
                      }}
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-name" size="small">
                      Position
                    </InputLabel>
                    <OutlinedInput
                      id="user-name"
                      label="Position"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={position}
                      onChange={(event) => {
                        setPosition(event.target.value);
                      }}
                      sx={{
                        borderRadius: 0,
                        height: "40px",
                      }}
                    />
                  </FormControl>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="DOB"
                    slotProps={{ textField: { size: "small" } }}
                    sx={{
                      borderRadius: "0px",
                      fontSize: "15px",
                      width: "100%",
                    }}
                    value={dobDate}
                    onChange={(newValue) => setDobDate(newValue)}
                  />
                </LocalizationProvider>
                <div className="flex gap-2 w-full">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-name" size="small">
                      Height(cm)
                    </InputLabel>
                    <OutlinedInput
                      id="user-name"
                      label="Height(cm)"
                      variant="outlined"
                      type="number"
                      size="small"
                      fullWidth
                      value={height}
                      onChange={(event) => {
                        setHeight(event.target.value);
                      }}
                      sx={{
                        borderRadius: 0,
                        height: "40px",
                      }}
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-name" size="small">
                      Weight(Kg)
                    </InputLabel>
                    <OutlinedInput
                      id="user-name"
                      label="Weight(Kg)"
                      variant="outlined"
                      type="number"
                      size="small"
                      fullWidth
                      value={weight}
                      onChange={(event) => {
                        setWeight(event.target.value);
                      }}
                      sx={{
                        borderRadius: 0,
                        height: "40px",
                      }}
                    />
                  </FormControl>
                </div>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-name" size="small">
                    Citizenship
                  </InputLabel>
                  <OutlinedInput
                    id="user-name"
                    label="Citizenship"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={citizenship}
                    onChange={(event) => {
                      setCitizenship(event.target.value);
                    }}
                    sx={{
                      borderRadius: 0,
                      height: "40px",
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-name" size="small">
                    Alumni
                  </InputLabel>
                  <OutlinedInput
                    id="user-name"
                    label="Alumni"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={alumni}
                    onChange={(event) => {
                      setAlumni(event.target.value);
                    }}
                    sx={{
                      borderRadius: 0,
                      height: "40px",
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-name" size="small">
                    Career beginning year
                  </InputLabel>
                  <OutlinedInput
                    id="user-name"
                    label="Career beginning year"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={careerBeginning}
                    onChange={(event) => {
                      setCareerBeginning(event.target.value);
                    }}
                    sx={{
                      borderRadius: 0,
                      height: "40px",
                    }}
                  />
                </FormControl>
                <div className="flex place-content-end mt-2 w-full">
                  <div className="sm:w-1/2 w-full flex  gap-3">
                    <Button
                      fullWidth
                      sx={useStyle.OutlineButton}
                      variant="outlined"
                      onClick={() => {
                        setAddPlayer(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      fullWidth
                      onClick={() => {
                        createPlayer();
                      }}
                      sx={useStyle.Button}
                      endIcon={posting ? <CircularProgress size={13} /> : <></>}
                    >
                      Add Player
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {viewPlayer && (
        <div className="w-full h-full bg-red-500 absolute">
          <ViewPlayer player={viewPlayer} closeMenu={setViewPlayer} />{" "}
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default Players;

import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import CreateUser from "../components/create_user";
import Chapters from "../components/chapters"
import { grey } from "@mui/material/colors";
import TeamMember from "../components/team_members";

const SettingsAdmin = () => {
  const [value, setValue] = useState(0);

  const tabs = [<CreateUser />, <TeamMember/> , <Chapters/>];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="flex flex-col w-full place-items-center">
      <div className="w-full border-b h-16 p-3 flex flex-col place-content-center">
        <p className="font-medium text-sm">Settings</p>
        <p className="text-xs text-slate-400">
          Manage your users and preferences
        </p>
      </div>
      <div className="bg-zinc-100 w-[98%] mt-3 ">
        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
          <Tab label="Add Team" sx={{textTransform :"none", color: grey}} />
          <Tab label="Team Members" sx={{textTransform :"none", color: grey}} />
          
          <Tab label="Chapters" sx={{textTransform :"none", color: grey}} />
        </Tabs>
      </div>

      <div className="w-full h-[81%] overflow-auto">
        {
          tabs[value]
        }
      </div>
    </div>
  );
};


export default SettingsAdmin;

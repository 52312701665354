import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import app from "../../../../utils/app";
import React, { useRef, useState } from "react";
import { months } from "../../../../utils/months";
import { BiCamera, BiEdit } from "react-icons/bi";
import DialogBackground from "../dialog_background";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { BsPerson } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { useStateContext } from "../../../../utils/contextprovider";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const Overview = ({ details }) => {
  const token = Cookies.get("token_highlander_admin");
  const [editImage, setEditImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [updatingContact, setUpdatingContact] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(details.email);
  const [address, setAddress] = useState(details.address);
  const [mobile, setMobile] = useState(details.mobile);
  const [viewImage, setViewImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [updatingDob, setUpdatingDob] = useState(false);
  const [dobDate, setDOBDate] = useState(dayjs(new Date()));
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const [img, setImg] = useState(null);
  const hiddenFileInput = useRef(null);
  const { userAdmin } = useStateContext();
  console.log(details);

  const uploadHandler = () => (event) => {
    var files = event.target.files;
    var arr = [];

    for (let i = 0; i < files.length; i++) {
      const obj = URL.createObjectURL(files[i]);
      const file = files[i];
      arr.push({ name: files.item(i).name, item: obj, file: file });
    }
    event.target.value = null;

    console.log(arr);
    setImg(arr[0]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };
  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const details = (hash >> (i * 8)) & 0xff;
      color += `00${details.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.includes(" ")
        ? `${name.split(" ")[0][0]}${
            name.split(" ")[1][0] === undefined ? "" : name.split(" ")[1][0]
          }`
        : `${name.split(" ")[0][0]}`,
    };
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
  };

  const uploadImage = () => {
    if (img === null) {
      setMessage("Please select image to upload");
      handleClickSnack();
      return;
    }
    const storage = getStorage(app);
    const ext = img.name.split(".")[1];
    var storageref = ref(
      storage,
      "profiles/" + Date.now() + "_" + details.fullname + "." + ext
    );
    const uploadTask = uploadBytesResumable(storageref, img.file);
    setUploading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
        setMessage("Upload failed try again");
        setUploading(false);
        handleClickSnack();
        return;
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          var detail = {
            img: downloadUrl,
            id: details.id_number,
          };

          axios
            .post(apiUrl + "update_member_image", detail, {
              headers: {
                "API-Key": apiKey,
                Authorization: `Bearer ${token}`,
              },
            })
            .then((result) => {
              details["member_img"] = downloadUrl;
              setMessage("Image uploaded");
              setUploading(false);
              handleClickSnack();
            })
            .catch((error) => {
              //console.log(error);
              setMessage("Failed to upload image");
              setUploading(false);
              handleClickSnack();
            });
        });
      }
    );
  };

  const submitUpdateContact = () => {
    setUpdatingContact(true);
    axios
      .post(
        apiUrl + "update_member_contacts",
        {
          email: email,
          mobile: mobile,
          address: address,
          id: details.id_number,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setMessage("Contacts updates");
        details["mobile"] = mobile;
        details["email"] = email;
        details["address"] = address;
        setUpdatingContact(false);
        handleClickSnack();
      })
      .catch((error) => {
        //console.log(error);
        setMessage("Failed to update contacts");
        setUpdatingContact(false);
        handleClickSnack();
      });
  };

  const updateDob = () => {
    setUpdatingDob(true);
    var dobdt =
      dobDate.$y +
      "-" +
      (dobDate.$M + 1).toString().padStart(2, "0") +
      "-" +
      dobDate.$D.toString().padStart(2, "0") +
      " " +
      "10:00" +
      ":" +
      dobDate.$m.toString().padStart(2, "0") +
      "+00:00";
    axios
      .post(
        apiUrl + "update_member_dob",
        {
          dob: dobdt,
          id: details.id_number,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setMessage("DOB updated");
        details["dob"] = dobDate;
        setUpdatingDob(false);
        handleClickSnack();
      })
      .catch((error) => {
        //console.log(error);
        setMessage("Failed to update dob");
        setUpdatingDob(false);
        handleClickSnack();
      });
  };
  return (
    <div className="w-full h-full flex flex-col sm:flex-row gap-2 p-3 pt-10 overflow-auto pb-16 sm:pb-0">
      <div className="sm:w-[23%] flex-shrink-0 w-full sm:h-[50%] h-[40%] bg-white rounded-lg border border-zinc-200 flex flex-col place-items-center p-5 gap-3">
        <div className="relative " style={{ height: "100px" }}>
          <Avatar
            variant="rounded"
            {...stringAvatar(details.fullname)}
            src={details.member_img}
            sx={{ height: "100px", width: "100px" }}
            className="cursor-pointer"
            onClick={() => {
              if (details.member_img !== "") {
                setViewImage(details.member_img);
              }
            }}
          />

          {!userAdmin.edit_member ? (
            <></>
          ) : (
            <div
              className="absolute bottom-1 right-1 bg-slate-400 w-10 h-10 flex place-content-center place-items-center rounded cursor-pointer"
              onClick={() => {
                setEditImage(true);
              }}
            >
              <BiCamera color="#fff" />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1 place-items-center">
          <p className="mt-2 text-[12px] font-medium ">{details.fullname}</p>
          <div
            className={
              details.sub === "LIFETIME"
                ? "h-6 px-2 rounded-sm flex place-content-center place-items-center bg-purple-500 gap-1"
                : "h-6 px-2 rounded-sm flex place-content-center place-items-center bg-zinc-400 gap-1"
            }
          >
            <p className="text-xs text-white font-semibold capitalize">
              {details.sub.toString().toLowerCase()}
            </p>
            <p className="text-xs text-white font-semibold">Member</p>
          </div>
        </div>
      </div>
      <div className="w-full sm:h-[50%] h-[40%]  border border-zinc-200 p-5 flex flex-col gap-3 rounded-lg">
        <p className="text-sm text-black font-semibold">Profile</p>
        <div className="w-full flex flex-col gap-3">
          <div className="flex w-full place-content-between">
            <p className="text-xs text-zinc-400">Id number</p>
            <p className="text-xs font-medium text-start">
              {details.id_number}
            </p>
          </div>
          <div className="flex w-full place-content-between">
            <p className="text-xs text-zinc-400">Member id</p>
            <p className="text-xs font-medium text-start">
              {details.membership_id}
            </p>
          </div>
          <div className="flex w-full place-content-between">
            <p className="text-xs text-zinc-400">DOB</p>
            <p className="text-xs font-medium text-start">
              {details.dob === null
                ? "-"
                : `${months[new Date(details.dob).getUTCMonth()]} ${new Date(
                    details.dob
                  ).getDate()}, ${new Date(details.dob).getUTCFullYear()}`}
            </p>
          </div>
          <div className="w-full flex place-content-end mt-8">
            {!userAdmin.edit_member ? (
              <></>
            ) : (
              <div
                className="w-10 h-10 border border-zinc-400 rounded-md flex place-content-center place-items-center cursor-pointer"
                onClick={() => {
                  setEditProfile(true);
                }}
              >
                <BiEdit className="text-zinc-400" />{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full sm:h-[50%] h-[40%]  border border-zinc-200 p-5 flex flex-col gap-3 rounded-lg">
        <p className="text-sm text-black font-semibold">Contacts</p>
        <div className="w-full flex flex-col gap-3">
          <div className="flex w-full place-content-between">
            <p className="text-xs text-zinc-400">Email</p>
            <p className="text-xs font-medium text-start">
              {details.email === null ? "No Email" : details.email}
            </p>
          </div>
          <div className="flex w-full place-content-between">
            <p className="text-xs text-zinc-400">Address</p>
            <p className="text-xs font-medium text-start">{details.address}</p>
          </div>
          <div className="flex w-full place-content-between">
            <p className="text-xs text-zinc-400">Mobile</p>
            <p className="text-xs font-medium text-start">{details.mobile}</p>
          </div>
          <div className="w-full flex place-content-end mt-8">
            {!userAdmin.edit_member ? (
              <></>
            ) : (
              <div
                className="w-10 h-10 border border-zinc-400 rounded-md flex place-content-center place-items-center cursor-pointer"
                onClick={() => {
                  setEditContact(true);
                }}
              >
                <BiEdit className="text-zinc-400" />{" "}
              </div>
            )}
          </div>
        </div>
      </div>

      {editImage && (
        <div className="w-full h-full absolute top-0 left-0 flex place-content-center place-items-center">
          <DialogBackground action={setEditImage} value={false} />

          <div className=" w-[95%] sm:w-[30vw] pb-5 bg-white absolute flex flex-col place-items-center pt-10 gap-5">
            {img === null ? (
              <div className="w-full flex place-content-center my-3">
                <div
                  onClick={handleClick}
                  className="w-20 h-20 rounded-full border border-black border-dashed flex place-content-center place-items-center gap-2 cursor-pointer"
                >
                  <BsPerson />
                </div>
              </div>
            ) : (
              <div className="w-full flex  place-content-center">
                <div
                  className="w-20 h-20 border rounded-full relative cursor-pointer my-3"
                  onClick={handleClick}
                >
                  <img
                    src={img.item}
                    alt="Cannot Load"
                    className="w-full h-full rounded-full"
                  />

                  <div
                    className="w-8 h-8 bg-slate-200 absolute right-1 bottom-0 rounded-full cursor-pointer flex place-content-center place-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImg(null);
                    }}
                  >
                    {" "}
                    <IoClose size={13} />{" "}
                  </div>
                </div>
              </div>
            )}

            <input
              hidden
              accept="image/*"
              type="file"
              ref={hiddenFileInput}
              onChange={uploadHandler()}
            />

            <div className="w-1/3 flex place-content-center">
              <Button
                sx={useStyle.Button}
                onClick={() => {
                  uploadImage();
                }}
                endIcon={uploading ? <CircularProgress size={14} /> : <></>}
                fullWidth
              >
                Upload Image
              </Button>
            </div>
          </div>
        </div>
      )}

      {viewImage && (
        <div className="w-full h-full absolute top-0 left-0 flex place-content-center place-items-center">
          <DialogBackground action={setViewImage} value={null} />

          <div
            className="w-[30vw] h-[40vh] bg-white absolute flex flex-col place-items-center pt-10 gap-5 bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: "url(" + viewImage + ")" }}
          />
        </div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {editProfile && (
          <div className="w-full h-full absolute top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setEditProfile(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="fixed sm:w-[35%] w-[95%] h-1/2 flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div className="w-full h-full pb-8 bg-white absolute flex flex-col place-content-center place-items-center gap-10 p-3">
                <div className="flex flex-col place-items-center">
                  <p className="text-lg text-black font-medium">Edit DOB ?</p>
                  <p className="text-xs text-slate-400">
                    Change the expiry date
                  </p>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expiry"
                    sx={{
                      borderRadius: "0px",
                      fontSize: "15px",
                      width: "100%",
                    }}
                    value={dobDate}
                    onChange={(newValue) => setDOBDate(newValue)}
                  />
                </LocalizationProvider>

                <div className="w-full">
                  <Button
                    sx={useStyle.Button}
                    onClick={() => {
                      if (!updatingDob) {
                        updateDob();
                      }
                    }}
                    fullWidth
                    endIcon={
                      updatingDob ? <CircularProgress size={12} /> : <></>
                    }
                  >
                    Update Subscription
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
      {editContact && (
        <div className="w-full h-full absolute top-0 left-0 flex place-content-center place-items-center ">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setEditContact(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="fixed w-[95%] sm:w-[40vw] h-1/2 flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
          <div className=" w-full h-full pb-10 bg-white absolute flex flex-col place-items-start gap-3 bg-cover bg-center bg-no-repeat p-3">
            <div className="flex flex-col gap-1 pb-5">
              <p className="font-medium text-sm">Edit Contacts</p>
              <p className="text-xs text-slate-400">Update user contact</p>
            </div>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-email" size="small">
                Email
              </InputLabel>

              <OutlinedInput
                id="user-email"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                sx={{
                  borderRadius: 0,
                  height: "40px",
                }}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-address" size="small">
                Address
              </InputLabel>

              <OutlinedInput
                id="user-email"
                label="Address"
                variant="outlined"
                size="small"
                fullWidth
                value={address}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
                sx={{
                  borderRadius: 0,
                  height: "40px",
                }}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-mobile" size="small">
                Mobile
              </InputLabel>

              <OutlinedInput
                id="user-mobile"
                label="Mobile"
                variant="outlined"
                size="small"
                fullWidth
                value={mobile}
                onChange={(event) => {
                  setMobile(event.target.value);
                }}
                sx={{
                  borderRadius: 0,
                  height: "40px",
                }}
              />
            </FormControl>

            <div className="w-full mt-5 flex place-content-end gap-2">
              <Button
                sx={useStyle.Button}
                onClick={() => {
                  submitUpdateContact();
                }}
                endIcon={
                  updatingContact ? <CircularProgress size={12} /> : <></>
                }
              >
                Update Contacts
              </Button>
              <Button
                sx={useStyle.Button}
                onClick={() => {
                  setEditContact(false);
                }}

                //endIcon={updating ? <CircularProgress size={12} /> : <></>}
              >
                Cancel
              </Button>
            </div>
          </div>
          </motion.div>
        </div>
      )}
      </AnimatePresence>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default Overview;

import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { MdPersonOutline } from "react-icons/md";
import Loading from "../admin/components/loading";
import ViewPlayerMember from "./components/view_player_member";

const PlayersMember = () => {
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [viewPlayer, setViewPlayer] = useState(null);
  const apiKey = process.env.REACT_APP_KEY;
  const apiUrl = process.env.REACT_APP_API;
  const token = Cookies.get("token_member_highlander");

  useEffect(() => {
    getPlayers();
  }, []);

  const getPlayers = () => {
    axios
      .get(apiUrl + "players", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoading(false);
        setPlayers(result.data.data);
        //console.log(result)
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="w-full h-14 border-b border-slate-300 flex place-content-between place-items-center sm:px-5">
        <div className="p-3 flex flex-col place-content-center">
          <p className="font-medium text-sm">Players</p>
          <p className="text-xs text-slate-500">
            View Club Players
          </p>
        </div>
        <div className="flex gap-2 w-[10rem] "></div>
      </div>
      {loading ? (
        <div className="flex w-full h-full place-content-center place-items-center">
          <Loading />{" "}
        </div>
      ) : players.length === 0 ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          <p className="text-xs">No Players</p>{" "}
        </div>
      ) : (
        <div
          className="w-full h-[83vh] overflow-auto flex flex-wrap gap-2 p-3"
          style={{ scrollbarWidth: "thin" }}
        >
          {players.map((value, index) => (
            <div
              className=" w-full sm:w-[20%] h-80 sm:h-72 bg-white cursor-pointer shadow-2xl rounded-md flex flex-col flex-shrink-0"
              key={index + "item"}
              onClick={() => {
                setViewPlayer(value);
              }}
            >
              <div className="flex place-content-center place-items-center border rounded-t-md w-full h-[65%]">
                {value.profile_img === "" ? (
                  <MdPersonOutline size={40} />
                ) : (
                  <img
                    src={value.profile_img}
                    alt=""
                    className="w-full h-full"
                  />
                )}
              </div>
              <div className="flex flex-col w-full p-2 gap-2">
                <div className="w-full flex place-content-between">
                  <p className="text-xs text-slate-400">Name:</p>
                  <p className="text-xs capitalize">{value.name}</p>
                </div>
                <div className="w-full flex place-content-between">
                  <p className="text-xs text-slate-400">Number:</p>
                  <p className="text-xs">{value.number}</p>
                </div>
                <div className="w-full flex place-content-between">
                  <p className="text-xs text-slate-400">Position:</p>
                  <p className="text-xs capitalize">{value.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

     
      {viewPlayer && (
        <div className="w-full h-full bg-red-500 absolute">
          <ViewPlayerMember player={viewPlayer} closeMenu={setViewPlayer} />{" "}
        </div>
      )} 
    </div>
  );
};

export default PlayersMember;

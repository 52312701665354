export const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };
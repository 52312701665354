import React from "react";

const NewsCard = ({ details }) => {
  console.log(details);
  return (
    <div className="w-full my-2 h-32 flex bg-slate-50 p-2 gap-2 font-helvetica cursor-pointer hover:shadow-sm">
      <div className="w-full h-full flex flex-col gap-2 pt-3">
        <p className="text-[15px] font-semibold line-clamp-2 capitalize">{details.title}</p>
        <p className="text-[11px] text-slate-500 line-clamp-2">{details.story}</p>
        <div className="flex gap-2 text-xs font-light text-slate-400 place-items-center">
          <p className="text-xs text-red-500 font-light capitalize">{details.tag}</p>
          <p>|</p>
          <p className="text-xs">{details.stat.text}</p>
        </div>
      </div>
      <div
        className="h-full w-36 rounded-md bg-slate-200 bg-cover bg-no-repeat bg-center"
        style={
          details.images.length !== 0
            ? {
                backgroundImage: "url(" + details.images[0] + ")",
              }
            : {}
        }
      />
    </div>
  );
};

export default NewsCard;

import React from "react";
import CloseButton from "./close_button";

const Header = ({ action, value, title, subtitle }) => {
  return (
    <div className="w-full h-12 place-items-center flex gap-8 border-b p-3">
      <CloseButton close={action} value={value} />
      <div className="flex flex-col gap-1">
        <p className="font-medium text-xs">{title}</p>
        <p className="text-xs text-slate-400">{subtitle}</p>
      </div>
    </div>
  );
};

export default Header;

import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyBlyCupQC6WV83xdFPWpxV2oYfg4oHeOfg",
    authDomain: "testinglab-e427b.firebaseapp.com",
    projectId: "testinglab-e427b",
    storageBucket: "testinglab-e427b.appspot.com",
    messagingSenderId: "1037030731977",
    appId: "1:1037030731977:web:ec41bd7655ef5a241a9d18",
    measurementId: "G-RKB3LV5S01"
};

const app = initializeApp(firebaseConfig);

export default app;
import React, { useState } from "react";
import { useStateContext } from "../../utils/contextprovider";
import { months } from "../../utils/months";
import { Avatar, Tab, Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";
import NewsBar from "./home/news_bar";
import UpdatesBar from "./home/updates_bar";
import { IoWarning } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";

const HomeMember = () => {
  const { user } = useStateContext();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [<NewsBar />, <UpdatesBar />];

  const frame =
    "<iframe src='https://www.scorebat.com/embed/competition/zimbabwe-premier-league/' frameborder='0' width='560' height='100%' allowfullscreen allow='autoplay; fullscreen' style='width:100%;overflow:hidden;' class='_scorebatEmbeddedPlayer_'></iframe><script>(function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = 'https://www.scorebat.com/embed/embed.js?v=arrv'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'scorebat-jssdk'));</script>";

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const details = (hash >> (i * 8)) & 0xff;
      color += `00${details.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.includes(" ")
        ? `${name.split(" ")[0][0]}${
            name.split(" ")[1][0] === undefined ? "" : name.split(" ")[1][0]
          }`
        : `${name.split(" ")[0][0]}`,
    };
  }
  return (
    <div className="w-full h-full flex sm:flex-row flex-col relative">
      <div className="w-full sm:w-1/2  h-full flex flex-col gap-3 p-5">
        <p className="text-sm text-black">Welcome</p>
        <div className="w-full h-48 flex-shrink-0 bg-zinc-100 flex p-3 place-content-between place-items-center">
          <div className="w-1/2 h-full flex flex-col">
            <p className="text-sm text-black line-clamp-1">
              Welcome back, {user.fullname}{" "}
            </p>
            <div className="mt-5 flex flex-col gap-1">
              <div className="flex  place-content-between">
                <p className="text-sm">Status:</p>

                <div
                  className={
                    new Date(user.expiry) < new Date()
                      ? "bg-red-300/50 rounded p-1 gap-1 flex place-content-center place-items-center"
                      : "bg-green-300/50 rounded p-1 gap-1 flex place-content-center place-items-center"
                  }
                >
                  {" "}
                  {new Date(user.expiry) < new Date() ? (
                    <p className="text-xs">
                      <IoWarning color="red" />
                    </p>
                  ) : (
                    <p className="text-xs">
                      <FaCheckCircle color="green" />
                    </p>
                  )}{" "}
                  {new Date(value.expiry) < new Date() ? (
                    <p className="text-xs text-black">Expired</p>
                  ) : (
                    <p className="text-xs text-black">Active</p>
                  )}{" "}
                </div>
              </div>

              <div className="flex  place-content-between">
                <p className="text-xs">Subscription:</p>
                <div
                  className={
                    user.subscription_type.toString().toLowerCase() !==
                    "ordinary"
                      ? "h-5 bg-purple-500/80 flex place-content-center place-items-center px-2 rounded-sm"
                      : "h-5 bg-zinc-500/80 flex place-content-center place-items-center px-2 rounded-sm"
                  }
                >
                  <p className="text-xs text-white capitalize">
                    {user.subscription_type.toString().toLowerCase()} Member
                  </p>
                </div>
                
              </div>
              <div className="flex  place-content-between">
                <p className="text-xs">Chapter:</p>
                <p className="text-xs">{user.chapter_name}</p>
              </div>
             
              <div className="flex  place-content-between">
                <p className="text-xs">Joined:</p>
                <p className="text-xs">
                  {" "}
                  {`${months[new Date(user.joined).getUTCMonth()]} ${new Date(
                    user.joined
                  ).getDate()}, ${new Date(user.joined).getUTCFullYear()}`}
                </p>
              </div>
              <div className="flex place-content-between">
                <p className="text-xs">Expiry:</p>
                <p className="text-xs">
                  {" "}
                  {`${months[new Date(user.expiry).getUTCMonth()]} ${new Date(
                    user.expiry
                  ).getDate()}, ${new Date(user.expiry).getUTCFullYear()}`}
                </p>
              </div>
            </div>
          </div>

          <Avatar
            {...stringAvatar(user.fullname)}
            src={user.member_img}
            sx={{ height: "140px", width: "120px" }}
            variant="rounded"
            className="cursor-pointer"
            onClick={() => {}}
          />
        </div>
        <p className="text-sm text-black">League results</p>
        <div
          dangerouslySetInnerHTML={{ __html: frame ? frame : "" }}
          className="w-full h-full"
          style={{ scrollbarWidth: "thin" }}
        />
      </div>
      <div className="w-full sm:w-1/2 h-full  border-l hidden sm:flex flex-col p-3">
        <div className="bg-zinc-100 w-full ">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="settings tabs"
          >
            <Tab
              label="Latest News"
              sx={{ textTransform: "none", color: grey }}
            />
            <Tab label="Updates" sx={{ textTransform: "none", color: grey }} />
          </Tabs>
        </div>
        <div
          className="w-full h-[95%] overflow-auto"
          style={{ scrollbarWidth: "thin" }}
        >
          {tabs[value]}
        </div>
      </div>
    </div>
  );
};

export default HomeMember;

import React, { useEffect } from "react";
import { Avatar, Button, IconButton, Pagination } from "@mui/material";
import NewsCard from "../components/news_card";
import { useState } from "react";
import DialogBackground from "../components/dialog_background";
import Header from "../components/header";
import axios from "axios";
import Loading from "../components/loading";
import SuchEmpty from "../components/empty";
import ErrorTryAgain from "../components/error_try_agin";
import { months } from "../../../utils/months";
import ViewNews from "../components/view_news";
import PublishArticle from "../components/publish_article";
import { IoReload } from "react-icons/io5";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const News = () => {
  const [publish, setPublish] = useState(false);
  const apiUrl = process.env.REACT_APP_API;
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [viewStory, setViewStory] = useState();
  const [loadingNews, setLoadingNews] = useState(true);
  const [newsError, setNewsError] = useState(false);
  const apiKey = process.env.REACT_APP_KEY;
  const token = Cookies.get("token_highlander_admin");

  useEffect(() => {
    getNews(1);
  }, []);

  /// Handle pagination request
  const handleChange = (event, value) => {
    //console.log(value);
    setPage(value);
    setLoadingNews(true);
    //console.log(value)
    getNews(value);
  };
  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
  };

  const getNews = (val) => {
    axios
      .post(
        apiUrl + "news",
        {
          page: val,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        //console.log(result.data)
        setPages(Math.ceil(result.data.total_news / 4));
        setNews(result.data.data);
        setLoadingNews(false);
      })
      .catch((error) => {
        setLoadingNews(false);
        setNewsError(true);
      });
  };

  const reload = () => {
    setLoadingNews(true);
    getNews(1);
    setPage(1);
  };
  return (
    <div className="w-full h-full  flex flex-col font-helvetica relative">
      <div className="w-full h-16 border-b flex place-items-center place-content-end p-3">
        <div className="flex gap-3">
          <Button
            sx={useStyle.Button}
            variant="contained"
            onClick={() => {
              setPublish(true);
            }}
          >
            Publish Article
          </Button>
          <IconButton
            onClick={() => {
              getNews(1);
            }}
          >
            <IoReload />
          </IconButton>
        </div>
      </div>
      <div className="w-full h-[81vh] flex sm:flex-row flex-col">
        {loadingNews ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <Loading />
          </div>
        ) : newsError ? (
          <div className="flex place-content-center place-items-center w-full">
            <ErrorTryAgain
              action={() => {
                reload();
              }}
            />{" "}
          </div>
        ) : news.length === 0 ? (
          <div className="flex place-content-center place-items-center w-full h-full">
            <SuchEmpty
              action={() => {
                reload();
              }}
              showButton={true}
            />
          </div>
        ) : (
          <>
            <div
              className="sm:w-1/2 w-full flex-shrink-0 h-1/3 sm:h-full p-3  bg-center bg-no-repeat cursor-pointer flex flex-col place-items-start gap-3"
           
              onClick={() => {
                setViewStory(news[0]);
              }}
            >
              
              <div className="p-2 rounded-sm sm:flex hidden flex-grow-0 place-content-center place-items-center gap-1 sm:gap-2">
                <Avatar variant="rounded" sizes="small"/>
                <div className="flex flex-col h-full place-content-start">
                <p className="capitalize text-xs">{news[0].fullname}</p>
                <p className="text-xs text-slate-400">Author</p>
                </div>
              </div>
              <div className="w-[90%]">
                <p className="text-lg sm:text-2xl font-semibold  line-clamp-3">
                  {news[0].title}
                </p>
              </div>

              <div className="flex gap-2 text-xs ">
                <p className="text-red-500">{news[0].tag}</p>
                <p className={news[0].fullname === null ? "hidden" : "flex"}>
                  |
                </p>
                <p>{news[0].stat.text}</p>
              </div>
              <div className="bg-cover bg-no-repeat bg-center w-full h-full rounded-md bg-slate-300" style={{ backgroundImage: "url(" + news[0].images[0] + ")" }}/>
            </div>
            <div className="flex flex-col w-full h-full gap-3 ">
              <div
                className="w-full h-[53vh] sm:h-[78vh] overflow-auto flex flex-col p-2"
                style={{ scrollbarWidth: "thin" }}
              >
                {news.map((value, index) => (
                  <div
                    onClick={() => {
                      setViewStory(value);
                    }}
                  >
                    <NewsCard key={index + "news"} details={value}></NewsCard>{" "}
                  </div>
                ))}
              </div>
              <Pagination page={page} count={pages} onChange={handleChange} />
            </div>
          </>
        )}
      </div>

      {viewStory && (
        <div className="w-full h-full bg-white  absolute top-0 left-0 flex flex-col place-items-center">
          <div className="w-full flex place-content-start p-3">
            <ViewNews setViewStory={setViewStory} viewStory={viewStory} />
          </div>
        </div>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {publish && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setPublish(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="fixed sm:w-1/2 w-[95%] sm:h-[95%] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <PublishArticle postAction={setPublish} reload={getNews} />
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default News;

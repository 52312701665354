import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { useStyle } from "../../../utils/styles";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";

const Email = () => {
  const [messageBody, setMessageBody] = useState("");
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [uploading, setUploading] = useState(false);
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const [to, setTo] = useState("All");
  const [open, setOpen] = useState(false);
  const token = Cookies.get("token_highlander_admin");

  const handleChangeTo = (e) => {
    setTo(e.target.value);
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMsg("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sendEmail = () => {
    if (title === "" || messageBody === "") {
        setMsg("Please fill in")
        handleClickSnack()
      return;
    }
    setUploading(true)
    axios
      .post(
        apiUrl + "send_emails",
        {
          to: to,
          subject: title,
          message: messageBody,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setMsg("Emails sent");
        setUploading(false);
        handleClickSnack();
      })
      .catch((error) => {
        console.log(error);
        setMsg("Failed to send emails");
        setUploading(false);
        handleClickSnack();
      });
  };
  return (
    <div className="flex flex-col sm:w-1/2 w-full h-full p-3 gap-3">
      <p className="text-xs">Send Emails</p>
      <FormControl size="small" fullWidth>
        <InputLabel id="demo-simple-select-label-chapter" size="small">
          Chapter
        </InputLabel>
        <Select
          labelId="demo-simple-select-label-chapter"
          id="demo-simple-select"
          value={to}
          label="Chapter"
          onChange={handleChangeTo}
          size="small"
          style={{
            borderRadius: "0px",
            fontSize: "15px",
          }}
        >
          <MenuItem value="All">All Members</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-title" size="small">
          Subject
        </InputLabel>
        <OutlinedInput
          sx={{
            borderRadius: 0,
          }}
          fullWidth
          size="small"
          id="outlined-adornment-title"
          label="Subject"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-message" size="small">
          Message
        </InputLabel>
        <OutlinedInput
          sx={{
            borderRadius: 0,
          }}
          fullWidth
          multiline
          rows={7}
          size="small"
          id="outlined-adornment-message"
          label="Message"
          value={messageBody}
          onChange={(event) => {
            setMessageBody(event.target.value);
          }}
        />
      </FormControl>
      <Button
        variant="outlined"
        onClick={() => {
          sendEmail();
        }}
        sx={useStyle.Button}
        endIcon={uploading ? <CircularProgress size={13} /> : <></>}
      >
        Send Email
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
    </div>
  );
};

export default Email;

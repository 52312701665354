import { CircularProgress } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <div className="w-16 h-16 flex place-content-center place-items-center rounded-sm shadow-lg bg-white absolute">
      <CircularProgress sx={{ color: "#f00" }} size={13} />
    </div>
  );
};

export default Loading;

import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Cookies from 'js-cookie';

const Report = ({closeReport}) => {
  const token = Cookies.get('token_highlander_admin');
  const [memberType, setMemberType] = useState("");
  const [status, setStatus] = useState("");
  const [chapter, setChapter] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setErrorChapter] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [loadingChapters, setLoadingChapters] = useState(true);
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;

  useEffect(() => {
    getAllChapters();
  }, []);

  const handleClickSnack = () => {
    setOpen(true);
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getAllChapters = () => {
    axios
      .get(apiUrl + "chapters", {
        headers: {
          "API-Key": apiKey,
          "Authorization": `Bearer ${token}`,
        }
      })
      .then((result) => {
        //console.log(result);
        setChapters(result.data.data);
        setLoadingChapters(false);
      })
      .catch((error) => {
        setMessage("Failed to get chapters try again");
        handleClickSnack();
        setLoadingChapters(false);
        setErrorChapter(true);
      });
  };

  const handleChangeType = (e) => {
    setMemberType(e.target.value);
  };
  const handleChangeChapter = (e) => {
    setChapter(e.target.value);
  };
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const generateReport = () => {
    if (status === "" || memberType === "" || chapter === "") {
      setMessage("Please select all options");
      handleClickSnack();
      return;
    }
    setLoadingReport(true);
    axios
      .post(
        apiUrl + "generate_report",
        {
          membership_type: memberType,
          status: status,
          chapter: chapter,
        },
        {
          headers: {
            "API-Key": apiKey,
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      .then(async(result) => {
        setLoadingReport(false);
        var data = result.data.data;
        if(data.length === 0){
          setMessage("No results found");
          handleClickSnack();
        } else {
          getReport(data, "report_" + Date.now())
        }
       
      })
      .catch((error) => {
        //console.log(error);
        setLoadingReport(false);
        setMessage("Failed to generate report");
        handleClickSnack();
      });
  };

  const getReport =(dataToSave,name)=>{
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=UTF-8'
    const ext = '.xlsx'
    //console.log(dataToSave)
      const ws = XLSX.utils.json_to_sheet(dataToSave);
      const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
      const excelBuffer = XLSX.write(wb, {bookType: 'xlsx',type: 'array'})
      const val = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(val, name + ext )

  }
  return (
    <div className="w-full h-full flex flex-col gap-5 p-3 py-5 bg-white rounded-md">
      <div className="w-full flex flex-col place-items-start">
        <p className="text-lg text-black font-medium">Generate Report ?</p>
        <p className="text-xs text-slate-400">
          Select values to generate report and gain insight into the club
          members
        </p>
      </div>
      <div className="w-full flex flex-col gap-2">
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label" size="small">
            Membership type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={memberType}
            label=" Membership type"
            onChange={handleChangeType}
            size="small"
          >
            <MenuItem value={"ALL"}>ALL</MenuItem>
            <MenuItem value={"LIFETIME"}>LIFETIME</MenuItem>
            <MenuItem value={"ORDINARY"}>ORDINARY</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label" size="small">
            Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            label="Status"
            onChange={handleChangeStatus}
            size="small"
          >
            <MenuItem value={"ALL"}>ALL</MenuItem>
            <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
            <MenuItem value={"EXPIRED"}>EXPIRED</MenuItem>
          </Select>
        </FormControl>
        {loadingChapters ? (
          <div className="w-full h-10 flex place-content-center place-items-center">
            {" "}
            <CircularProgress size={13} />{" "}
          </div>
        ) : error ? (
          <p
            className="text-xs cursor-pointer"
            onClick={() => {
              setLoadingChapters(true);
              setErrorChapter(false);
              getAllChapters();
            }}
          >
            Reload chapters
          </p>
        ) : (
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label" size="small">
              Chapter
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={chapter}
              label="Chapter"
              onChange={handleChangeChapter}
              size="small"
            >
              <MenuItem value={"ALL"}>ALL</MenuItem>
              {chapters.map((value, index) => (
                <MenuItem key={index + "chapter"} value={value.chapter_name}>
                  {value.chapter_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className="w-full flex place-content-end ">
        <div className="sm:w-1/2 w-full flex gap-2">
        <Button
        sx={useStyle.OutlineButton}
        fullWidth
        onClick={() => {
          closeReport(false)
        }}
        variant="outlined"
        
      >
        Cancel
      </Button>
        <Button
        sx={useStyle.Button}
        fullWidth
        onClick={() => {
          generateReport();
        }}
        endIcon={loadingReport ? <CircularProgress size={15} /> : <></>}
      >
        Generate Report
      </Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default Report;

import React, { useState } from 'react'
import { useStateContext } from '../utils/contextprovider';
import { Navigate } from "react-router-dom";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import hbadge from "../assets/images/badge.png";
import ngenkani from "../assets/images/ngenkani.png";
import axios from "axios"
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import { AiOutlineClose } from 'react-icons/ai';
import Cookies from 'js-cookie';

const AdminLogin = () => {
  const { userAdmin, setUserAdmin } = useStateContext();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [showpass, setShowPass] = useState("");
  const [errorMessage,setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading,setLoading] = useState(false)
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;

  
  /// get the token as a cookie
  const token = Cookies.get('token_highlander_admin');

  /// if cookie is there go to home
  if (token) {
    return <Navigate to="/admin/home" />;
  }

  const handleClickShowPassword = () => {
    setShowPass(!showpass);
  };


  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleLogin = () => {
    if(loading){
      return
    }
    if(password === "" || userId === ""){
      setErrorMessage("Please fill in your details")
      handleClickSnack()
      return
    }
    setLoading(true);

    //console.log(apiUrl)

    axios.post(apiUrl+"auth",
      {
        role: "admin",
        password: password,
        username: userId
      }, {
        headers: {
          "API-Key": apiKey
        }
      }
    ).then(result => {
      setLoading(false);
      //console.log(result.data.token)
      Cookies.set('token_highlander_admin', result.data.token, { expires: 7, secure: true });
      setUserAdmin(result.data.data)
    }).catch(error => {
      console.log("here")
      
      setErrorMessage(error.response === undefined ? "Error try again later" : error.response.data.data)
      setLoading(false)
      handleClickSnack()
    })
  }

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
  };
  
  return (
    <div className='w-full h-screen flex place-content-center place-items-center p-5 bg-[#161415]'>
      <div className='sm:w-[32%] w-[95%] h-[70%] sm:h-3/4 bg-white shadow-lg shadow-gray-400 rounded-md flex flex-col place-items-center p-5 sm:p-10 gap-3'>
      <div
            className=" sm:w-[45%] w-full h-[35%] bg-center bg-cover"
            style={{ backgroundImage: "url(" + hbadge + ")" }}
          />
          <div
            className=" h-12 w-1/2 bg-center bg-cover"
            style={{ backgroundImage: "url(" + ngenkani + ")" }}
          />

          <div className="mt-3 flex flex-col gap-2 w-full">
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-username" size="small">
                Username
              </InputLabel>
              <OutlinedInput
                id="user-id"
                label="Member ID"
                variant="outlined"
                size="small"
                fullWidth
                value={userId}
                onChange={(event) => {
                  setUserId(event.target.value);
                }}
                sx={{
                  borderRadius: 0,
                  height: "40px",
                }}
              />
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" size="small">
                Password
              </InputLabel>
              <OutlinedInput
                sx={{
                  borderRadius: 0,
                  height: "40px",
                }}
                fullWidth
                size="small"
                id="outlined-adornment-password"
                label="Password"
                type={showpass ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showpass ? (
                        <MdOutlineVisibilityOff size={15} />
                      ) : (
                        <MdOutlineVisibility size={15} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </FormControl>
            <div className="mt-3 w-full">
              <Button
                variant="contained"
                fullWidth
                sx={useStyle.Button}
                onClick={() => {
                  
                  handleLogin()
                }}

                endIcon={
                  loading ? <CircularProgress sx={{color: "white"}} size={15}/> : <></>
                }
              >
                Login
              </Button>
            </div>
          </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMessage}
        action={action}
      />
    </div>
  )
}

export default AdminLogin
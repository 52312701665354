import React from 'react'
import pr from "../../assets/images/privacy.jpg"
import ngenkani from "../../assets/images/ngenkani.png";
import hbadge from "../../assets/images/badge.png";
const Privacy = () => {
  return (
    <div className='w-full h-screen bg-[#161415] flex place-content-center place-items-center'>

        <div className='w-1/2 h-full bg-center bg-cover sm:flex hidden' style={{backgroundImage: "url(" + pr +")"}}/>

        <div className='sm:w-1/2 w-full flex py-10 gap-3 flex-col h-full place-content-center place-items-center'>
     <div className='bg-white flex flex-col w-[90%] h-[95%] shadow-lg shadow-gray-400 rounded-md place-items-center py-5 gap-3 px-5'>
     <div
            className=" sm:w-[15%] w-1/2 h-[18%] bg-center bg-cover"
            style={{ backgroundImage: "url(" + hbadge + ")" }}
          />
        <div
            className=" h-5 sm:w-[20%] w-[40%] bg-center bg-cover"
            style={{ backgroundImage: "url(" + ngenkani + ")" }}
          />

          <p className='text-slate-500 text-xs text-center'>We at Bosso take your privacy very seriously that is why we have compiled this list for you to know your privacy rights when using this website. <span className='text-red-500 font-medium underline'>Your safety is our number one priority</span></p>
          
          <ul className='text-xs mt-5'>
            <li className='flex gap-2'>
                <p>1.</p>
                <p>Your information will not be shared with any other third party unless they are law enforcement agents with a proper legal warrant and required by law to do so</p>
            </li>
            <li className='flex gap-2 mt-2'>
                <p>2.</p>
                <p>Your credentials and other sensitive information is stored in a secure database. All you passwords are hashed before they are store for added security</p>
            </li>
            <li className='flex gap-2 mt-2'>
                <p>3.</p>
                <p>To request for your information to be deleted please contact your local chapter admin or visit any branch near you</p>
            </li>
            <li className='flex gap-2 mt-2'>
                <p>4.</p>
                <p>You can request more information on the data that we collect from your chapel admin.</p>
            </li>
            <li className='flex gap-2 mt-2'>
                <p>5.</p>
                <p>We do not collect or store any credit card information for any member</p>
            </li>
          </ul>
        </div>
     </div>

    </div>
  )
}

export default Privacy
import { MdHome, MdMessage, MdNewspaper, MdPerson, MdScore, MdSettings, MdSportsSoccer, MdUpdate } from "react-icons/md";

//Links for admin dashboard
const links = [
    {
        "title": "Home",
        "link": "/admin/home",
        "icons": <MdHome/>
    },
    
    {
        "title": "Members",
        "link": "/admin/members",
        "icons": <MdPerson/>
    },
    {
        "title": "News",
        "link": "/admin/news",
        "icons": <MdNewspaper/>
    },
    {
        "title": "Players",
        "link": "/admin/players",
        "icons": <MdSportsSoccer/>
    },
    {
        "title": "Alerts",
        "link": "/admin/alerts",
        "icons":<MdMessage/>
    },
    {
        "title": "Settings",
        "link": "/admin/settings",
        "icons":<MdSettings/>
    },
    
];

export default links;
import { IconButton } from '@mui/material';
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { MdPersonOutline } from 'react-icons/md';

const ViewPlayerMember = ({ player, closeMenu }) => {
  return (
    <div className="w-full h-full bg-white flex flex-col relative">
      <div className="w-full h-10 flex place-items-center border-b p-3 place-content-between">
        <IconButton
          onClick={() => {
            closeMenu(null);
          }}
        >
          <AiOutlineClose size={15} />
        </IconButton>
      
      </div>
      <div className="h-[85vh] flex flex-col sm:flex-row  w-full">
        <div className="w-full sm:w-1/2 sm:h-full h-1/2 flex place-content-center place-items-center border border-t-0">
          {player.profile_img === "" ? (
            <MdPersonOutline size={80} />
          ) : (
            <img src={player.profile_img} alt="" className="w-full h-full" />
          )}
        </div>
        <div className="w-full  sm:w-1/2 sm:h-full h-1/2 flex flex-col p-8 gap-3">
          <p className=" text-xs sm:text-4xl font-semibold">{player.number}</p>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">Name:</p>
            <p className="text-sm capitalize">{player.name}</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">Citizenship:</p>
            <p className="text-sm capitalize">{player.citizenship}</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">position:</p>
            <p className="text-sm capitalize">{player.position}</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">weight:</p>
            <p className="text-sm capitalize">{player.weight} kg</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">height:</p>
            <p className="text-sm">{player.height} cm</p>
          </div>
          <div className="flex sm:w-3/4 place-content-between place-items-end">
            <p className=" text-xs text-slate-400">Career beginning:</p>
            <p className="text-sm">{player.career_beginning}</p>
          </div>
        </div>
      </div>

      
     
    </div>
  )
}

export default ViewPlayerMember
import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import Notifications from "../components/notifications";
import Updates from "../components/updates";
import { grey } from "@mui/material/colors";
import Email from "../components/email";
import SMS from "../components/sms";

const Alerts = () => {
    const [value, setValue] = useState(0);

  const tabs = [<Notifications />, <Updates/>,<Email/>, <SMS/>];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="flex flex-col w-full place-items-center">
      <div className="w-full border-b h-16 p-3 flex flex-col place-content-center">
        <p className="font-medium text-sm">Alerts</p>
        <p className="text-xs text-slate-500">
          Send notifications and updates to members
        </p>
      </div>
      <div className="bg-zinc-100 w-[98%] mt-3  overflow-auto" style={{scrollbarWidth: "thin"}}>
        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
          
          <Tab
            label="Notifications"
            sx={{ textTransform: "none", color: grey }}
          />
          <Tab label="Updates" sx={{ textTransform: "none", color: grey }} />
          <Tab label="Email" sx={{ textTransform: "none", color: grey }} />
          {/**<Tab label="SMS" sx={{ textTransform: "none", color: grey }} /> */}
        
        </Tabs>
      </div>
      <div className="w-full h-[81%] overflow-auto">
        {
          tabs[value]
        }
      </div>
    </div>
  );
};

export default Alerts;

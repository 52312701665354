import axios from "axios";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../utils/contextprovider";
import Loading from "../admin/components/loading";
import { BiBell } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { IconButton, Snackbar } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const Inbox = () => {
  const { user } = useStateContext();
  const [errorNotification, setErrorNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loadingNotification, setLoadingNotification] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(null);
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const token = Cookies.get("token_member_highlander");

  useEffect(() => {
    getNotifications();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorMsg("");
  };
  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const updateNotification = (uid, id, index) => {
    // console.log("here");
    axios
      .post(
        apiUrl + "viewed_update",
        {
          user_id: uid,
          id: id,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        var val = [...notifications];
        var arr = [...val[index].viewed_by, uid];

        val[index].viewed_by = arr;

        setNotifications(val);
      })
      .catch((error) => {
        setErrorMsg("Failed to upate please try again");
        handleClickSnack();
      });
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const getNotifications = () => {
    axios
      .post(
        apiUrl + "get_notifications",
        {
          id: user.id_number,
          role: "member",
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setLoadingNotification(false);
        console.log(result.data.data);
        setNotifications(result.data.data);
      })
      .catch((error) => {
        //update error here
        setLoadingNotification(false);
        setErrorNotification(true);
      });
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full border-b h-16 p-3 flex flex-col place-content-center">
        <p className="font-medium text-sm">Inbox</p>
        <p className="text-xs text-slate-400">
          Get all notifications and messages
        </p>
      </div>

      <div className="sm:w-1/2 w-full  h-[82vh] p-3">
        {loadingNotification ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <Loading />
          </div>
        ) : errorNotification ? (
          <div className="flex place-content-center place-items-center">
            <p
              className="text-xs cursor-pointer"
              onClick={() => {
                setLoadingNotification(true);
                setErrorNotification(false);
                getNotifications();
              }}
            >
              Reload
            </p>{" "}
          </div>
        ) : notifications.length === 0 ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <p className="text-xs">No Notifications or messages found</p>{" "}
          </div>
        ) : (
          <div className="w-full h-full flex flex-col">
            {notifications.map((value, index) => (
              <div
                key={index + "message"}
                className="w-full h-16 rounded border border-zinc-300 flex place-items-center p-3 gap-3 cursor-pointer"
                onClick={() => {
                  if (!value.viewed_by.includes(user.id_number)) {
                    updateNotification(user.id_number, value.id, index);
                  }
                  setMsg(value);
                }}
              >
                <div className="w-10 h-10 rounded border-slate-300 border flex place-content-center place-items-center relative">
                  <BiBell />
                  <div
                    className={
                      value.viewed_by.includes(user.id_number)
                        ? "hidden"
                        : "absolute bg-red-500 w-2 h-2 rounded left-0 top-0"
                    }
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-medium text-black">
                    {value.title}
                  </p>
                  <p className="text-xs text-zinc-400 line-clamp-1">
                    {value.message}
                  </p>
                </div>
              </div>
            ))}{" "}
          </div>
        )}
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {msg && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setMsg(null);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute w-[95%] sm:w-1/3 h-[60vh] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div className="w-full h-full bg-white rounded-sm absolute z-[99999]">
                <div className="p-3 border-b w-full h-14 flex place-content-between">
                  <p className="text-sm text-slate-400">Notification</p>
                  <div
                    className="w-8 h-8 rounded border flex place-content-center place-items-center cursor-pointer hover:bg-slate-100"
                    onClick={() => {
                      setMsg(null);
                    }}
                  >
                    <MdClose />{" "}
                  </div>
                </div>
                <div className="flex flex-col p-5 gap-3">
                  <div className="flex gap-3 text-sm">
                    <p className="text-slate-400">Title:</p>
                    <p>{msg.title}</p>
                  </div>

                  <p className="text-sm">{msg.message}</p>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMsg}
        action={action}
      />
    </div>
  );
};

export default Inbox;

import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";

const Chapters = () => {
  const [location, setLocation] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locations, setLocations] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createloading, setCreateLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const token = Cookies.get("token_highlander_admin");

  useEffect(() => {
    getLocations();
  }, []);

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getLocations = () => {
    axios
      .get(apiUrl + "chapters", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        console.log(result);
        setLocations(result.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setMessage("Failed to get chapters try again");
        handleClickSnack();
        setLoading(false);
        setError(true);
      });
  };
  const uploadLocation = () => {
    if (locationName === "" || location === "") {
      setMessage("Please complete the form");
      handleClickSnack();
      return;
    }
    setCreateLoading(true);
    axios
      .post(
        apiUrl + "create_chapters",
        {
          chapter_name: locationName,
          location: location,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setMessage("Chapter created");
        handleClickSnack();
        setLocation("");
        setLocationName("");
        getLocations();
        setCreateLoading(false);
      })
      .catch((error) => {
        setMessage("Failed to create chapter");
        handleClickSnack();
        setCreateLoading(false);
      });
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#FFF",
    },
  };
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex  sm:flex-row flex-col-reverse border-b">
        <div className="sm:w-1/2 w-full h-36 sm:h-56 p-3 sm:p-10 gap-7 flex flex-col place-content-center place-items-center sm:place-content-start sm:place-items-start">
          <div className="flex flex-col gap-1">
            <p className="font-medium text-sm">New Chapter</p>
            <p className="text-xs text-slate-500">Create a new chapter</p>
          </div>

          <div className="w-full sm:w-[40%]">
            <Button
              sx={useStyle.Button}
              fullWidth
              onClick={() => {
                if (!createloading) {
                  uploadLocation();
                }
              }}
              endIcon={createloading ? <CircularProgress size={13} /> : <></>}
            >
              Add Location
            </Button>
          </div>
        </div>
        <div className="sm:w-1/2 w-full h-36 sm:h-56 p-3 sm:p-7 flex flex-col gap-2 place-content-center ">
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-location-name" size="small">
              Location name
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: 0,
                height: "40px",
              }}
              fullWidth
              size="small"
              id="outlined-adornment-location-name"
              label="Location Name"
              value={locationName}
              onChange={(event) => {
                setLocationName(event.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-location" size="small">
              Location
            </InputLabel>
            <OutlinedInput
              sx={{
                borderRadius: 0,
                height: "40px",
              }}
              fullWidth
              size="small"
              id="outlined-adornment-location"
              label="Location"
              value={location}
              onChange={(event) => {
                setLocation(event.target.value);
              }}
            />
          </FormControl>
        </div>
      </div>

      <div className="flex flex-col w-full h-[60%] p-3 sm:p-10  gap-5">
        <div className="flex flex-col gap-1">
          <p className="font-medium text-sm">All Chapters</p>
          <p className="text-xs text-slate-400">See all chapters available</p>
        </div>
        <div
          className="w-full h-[25vh]  overflow-auto"
          style={{ scrollbarWidth: "thin" }}
        >
          {loading ? (
            <div className="w-full h-full flex place-content-center place-items-center">
              <CircularProgress />{" "}
            </div>
          ) : locations.length === 0 ? (
            <div className="flex place-content-center place-items-center">
              <p
                className="text-xs cursor-pointer"
                onClick={() => {
                  setLoading(true);
                  getLocations();
                }}
              >
                No Items found tap to reload
              </p>{" "}
            </div>
          ) : error ? (
            <div className="flex place-content-center place-items-center">
              <p
                className="text-xs cursor-pointer"
                onClick={() => {
                  setLoading(true);
                  setError(false);
                  getLocations();
                }}
              >
                Error loading tap to reload
              </p>{" "}
            </div>
          ) : (
            <table className="border border-slate-300 w-full">
              <tr className="border-b border-slate-300 h-8  text-xs">
                <th className=" h-10  border-slate-300 min-w-8 max-h-8">
                  <p className="text-xs font-semibold">Id</p>
                </th>
                <th className=" h-10  border-slate-300 min-w-8 max-h-8">
                  <p className="text-xs font-semibold">Chapter name</p>
                </th>
                <th className=" h-10  border-slate-300 min-w-8 max-h-8">
                  <p className="text-xs font-semibold">Chapter location</p>
                </th>
              </tr>

              {locations.map((value, index) => (
                <tr
                  key={index + "location"}
                  className={
                    index % 2 === 0
                      ? "w-full h-10 border-b border-slate-300 text-center cursor-pointer hover:bg-slate-300"
                      : "w-full h-10 border-b border-slate-300 text-center cursor-pointer hover:bg-slate-300 bg-slate-100"
                  }
                >
                  <td className=" border-slate-300 text-xs p-3">{value.id}</td>
                  <td className=" border-slate-300 text-xs p-3">
                    {value.chapter_name}
                  </td>
                  <td className=" border-slate-300 text-xs p-3">
                    {value.location}
                  </td>
                </tr>
              ))}
            </table>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default Chapters;

import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Loading from "./loading";
import DialogBackground from "./dialog_background";
import { months } from "../../../utils/months";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const Updates = () => {
  const token = Cookies.get("token_highlander_admin");
  const [allUpdates, setAllUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendUpdate, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;

  useEffect(() => {
    getUpdates();
  }, []);

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getUpdates = () => {
    /// get all updates sent out
    axios
      .get(apiUrl + "updates", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoading(false);
        setAllUpdates(result.data.data);
        setTitle("");
        setMessage("");
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setMessage("Error loading please try again");
        handleClickSnack();
      });
  };

  const sendUpdates = () => {
    if (title === "" || messageBody === "") {
      setMessage("Please fill in");
      handleClickSnack();
      return;
    }
    setSendingMessage(true);
    axios
      .post(
        apiUrl + "post_updates",
        {
          title: title,
          description: messageBody,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setMessage("Update sent");
        getUpdates();
        setSendingMessage(false);
        handleClickSnack();
      })
      .catch((error) => {
        setMessage("Failed to send update");
        setSendingMessage(false);
        handleClickSnack();
      });
  };

  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };
  return (
    <div className="flex flex-col w-full h-full">
      <div className="h-16 w-full flex p-5 place-content-end border-b">
        <div className="sm:w-[10vw]">
          <Button
            sx={useStyle.Button}
            fullWidth
            onClick={() => {
              setUpdate(true);
            }}
          >
            Send Updates
          </Button>
        </div>
      </div>

      <div className=" w-full h-[65vh] flex flex-col overflow-auto">
        {loading ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <Loading />{" "}
          </div>
        ) : error ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <p
              className="text-xs cursor-pointer"
              onClick={() => {
                setLoading(true);
                setError(false);
                getUpdates();
              }}
            >
              Error loading tap to try again
            </p>{" "}
          </div>
        ) : allUpdates.length === 0 ? (
          <div className="w-full h-full flex place-content-center place-items-center">
            <p className="text-xs">No updates found</p>{" "}
          </div>
        ) : (
          <div
            className="w-full sm:w-1/2 h-full flex flex-col pt-3 overflow-auto"
            style={{ scrollbarWidth: "thin" }}
          >
            {allUpdates.map((value, index) => (
              <div className="flex gap-5 p-3 pb-0 pt-0" key={"update" + index}>
                <div className="flex flex-col place-items-center">
                  <div className="w-2 h-2 rounded-full flex flex-shrink-0 border border-zinc-500" />
                  <div className="w-[1px] h-full bg-zinc-400" />
                </div>

                <div className="w-full flex flex-col gap-3 p-3">
                  <p className="text-sm text-slate-400 line-clamp-1">
                    {`${
                      months[new Date(value.date_posted).getUTCMonth()]
                    } ${new Date(value.date_posted).getDate()}, ${new Date(
                      value.date_posted
                    ).getUTCFullYear()}`}
                  </p>

                  <p className="text-lg">{value.title}</p>

                  <p className="text-xs">{value.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />

      <AnimatePresence mode="wait" initial={false}>
        {sendUpdate && (
          <div className="absolute w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="absolute bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setUpdate(false);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[40%] w-[95%] h-[60vh] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className="w-full h-full pb-6 bg-white rounded-sm absolute z-[99999]
          flex flex-col place-content-start p-3 gap-3 pt-10  "
              >
                <div className="flex flex-col gap-1">
                  <p className="font-medium text-sm">Post Update</p>
                  <p className="text-xs text-slate-400">
                    Send updates to the members
                  </p>
                </div>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-title" size="small">
                    Title
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      borderRadius: 0,
                    }}
                    fullWidth
                    size="small"
                    id="outlined-adornment-title"
                    label="Title"
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-message" size="small">
                    Message
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      borderRadius: 0,
                    }}
                    fullWidth
                    multiline
                    rows={7}
                    size="small"
                    id="outlined-adornment-message"
                    label="Message"
                    value={messageBody}
                    onChange={(event) => {
                      setMessageBody(event.target.value);
                    }}
                  />
                </FormControl>
                <div className="w-full flex place-content-end mt-10 gap-2">
                  <Button
                    sx={useStyle.Button}
                    onClick={() => {
                      if (!sendingMessage) {
                        sendUpdates();
                      }
                    }}
                    endIcon={
                      sendingMessage ? <CircularProgress size={13} /> : <></>
                    }
                  >
                    Send Notification
                  </Button>
                  <Button
                    onClick={() => {
                      setUpdate(false);
                    }}
                    sx={useStyle.OutlineButton}
                    variant="outlined"
                  >
                    Cancel
                  </Button>{" "}
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      {sendUpdate && (
        <div className="w-full h-full absolute top-0 left-0 z-[999] flex place-content-center place-items-center">
          <DialogBackground action={setUpdate} value={false} />
        </div>
      )}
    </div>
  );
};

export default Updates;

import { Button } from "@mui/material";
import React from "react";
import { IoReload } from "react-icons/io5";

const SuchEmpty = ({ showButton, action }) => {
  return (
    <div className="w-full h-full flex flex-grow place-content-center place-items-center flex-col gap-5">
      <IoReload color="#f34abf" size={20} />
      <p className="text-sm font-helvetica font-normal text-slate-400">
        No Items Found
      </p>
      <Button
        variant="contained"
        sx={{ color: "#fff", backgroundColor: "#000" }}
        onClick={action}
      >
        Reload
      </Button>
    </div>
  );
};

export default SuchEmpty;

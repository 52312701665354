
import React, { useState } from "react";

const ImgCarousel = ({ images }) => {
  const [img,setImg] = useState(images[0])
  return (
    <div className="relative h-full w-full flex place-content-center place-items-center bg-center bg-contain bg-no-repeat" style={{backgroundImage: 'url(' + img + ')'}}>
      <div className="w-full h-16  absolute bottom-5 flex place-content-center gap-3">
      {images.map((val, index) => (
        <div className="w-16 h-full bg-slate-300 flex place-content-center cursor-pointer" onClick={() => {setImg(val)}}>
          <img
            key={"img" + index}
            src={val}
            aria-hidden
            alt={"Image" + index}
            className="h-full w-full object-cover"
          />
        </div>
      ))}
      </div>
    </div>
  );
};

export default ImgCarousel;

import React, { useEffect, useState } from "react";
import Loading from "./loading";
import axios from "axios";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import {
  MdDeleteOutline,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import { useStateContext } from "../../../utils/contextprovider";
import Cookies from "js-cookie";
import { AnimatePresence, motion } from "framer-motion";

const TeamMember = () => {
  const token = Cookies.get("token_highlander_admin");
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [showpass, setShowPass] = useState(false);
  const [password, setPassword] = useState("");
  const [editUser, setEditUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [open, setOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API;
  const apiKey = process.env.REACT_APP_KEY;
  const { userAdmin } = useStateContext();

  useEffect(() => {
    getTeamMembers();
  }, []);

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setMessage("");
  };

  const framerSidebarPanel = {
    initial: { y: "-100%" },
    animate: { y: 0 },
    exit: { y: "-100%" },
    transition: { duration: 0.3 },
  };
  const framerSidebarBackground = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3 },
  };

  const useStyle = {
    Button: {
      "&:hover": {
        backgroundColor: "#CE1729 !important",
        boxShadow: "#ffffff !important",
      },
      backgroundColor: "#161415",
      textTransform: "none",
      color: "#fff",
    },
    OutlineButton: {
      textTransform: "none",
      borderColor: "#2E4053",
      color: "#2E4053",
    },
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <AiOutlineClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const getTeamMembers = () => {
    axios
      .get(apiUrl + "get_team", {
        headers: {
          "API-Key": apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoading(false);
        setMembers(result.data.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setMessage("Error loading try again");
        handleClickSnack();
      });
  };

  const deleteTheUser = () => {
    axios
      .post(
        apiUrl + "delete_team_member",
        {
          id: deleteUser.id_number,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setDeleteUser(null);
        getTeamMembers();
        setMessage("User Deleted");
        handleClickSnack();
      })
      .catch((error) => {
        console.log(error);
        setMessage("Error Deleting try again");
        handleClickSnack();
      });
  };

  const handleClickShowPassword = () => {
    setShowPass(!showpass);
  };

  const updateUserPassword = () => {
    if (password === "") {
      setMessage("Failed please fill in new password");
      handleClickSnack();
      return;
    }

    setUpdatePassword(true);

    axios
      .post(
        apiUrl + "update_password",
        {
          new_password: password,
          user_id: editUser.id_number,
        },
        {
          headers: {
            "API-Key": apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setUpdatePassword(false);
        setMessage("Password Changed");
        handleClickSnack();
      })
      .catch((error) => {
        setUpdatePassword(false);
        setMessage("Failed please try again");
        handleClickSnack();
      });
  };

  return (
    <div className="w-full h-full ">
      {loading ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          <Loading />
        </div>
      ) : error ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          <p
            className="text-sm font-normal cursor-pointer"
            onClick={() => {
              setLoading(true);
              setError(false);
              getTeamMembers();
            }}
          >
            Error loading tap to try again
          </p>{" "}
        </div>
      ) : members.length === 0 ? (
        <div className="w-full h-full flex place-content-center place-items-center">
          <p className="text-sm text-slate-500">No Members Found</p>
        </div>
      ) : (
        <div className="w-full h-full p-3 ">
          <table className="border border-slate-400 w-full  text-xs">
            <thead className="border-b border-slate-400">
              <th className=" h-12 min-w-20 border-slate-400 "></th>
              <th className=" h-12 min-w-24 border-slate-400 ">
                <p className="text-xs font-semibold">Full name</p>
              </th>
              <th className=" h-12 min-w-24 border-slate-400 ">
                <p className="text-xs font-semibold">Username</p>
              </th>
              <th className=" min-w-24 border-slate-400 ">
                <p className="text-xs font-semibold">ID Number</p>
              </th>

              <th className=" h-12 min-w-24 border-slate-400">
                <p className="text-xs font-semibold">Email</p>
              </th>
              <th className=" h-12 min-w-24 border-slate-400 ">
                <p className="text-xs font-semibold">Role</p>
              </th>
              <th className=" h-12 min-w-24 border-slate-400 ">
                <p className="text-xs font-semibold">Actions</p>
              </th>
            </thead>

            <tbody>
              {members.map((val, index) => (
                <tr
                  key={"member" + index}
                  className={
                    index % 2 === 0
                      ? "border-b border-slate-400 bg-slate-100 cursor-pointer hover:bg-slate-300"
                      : "border-b border-slate-400 cursor-pointer hover:bg-slate-300"
                  }
                >
                  <td className=" px-2 h-10 border-slate-400 flex place-content-center place-items-center">
                    <Avatar
                      variant="rounded"
                      {...stringAvatar(val.fullname)}
                      src={val.profile_img}
                      sx={{ height: "30px", width: "30px", fontSize: "12px" }}
                    />
                  </td>
                  <td className=" px-2 border-slate-400">
                    <p className="text-xs  text-center">{val.fullname}</p>
                  </td>
                  <td className=" px-2 border-slate-400">
                    <p className="text-xs text-center">{val.username}</p>
                  </td>
                  <td className=" px-2 border-slate-400">
                    <p className="text-xs  text-center">{val.id_number}</p>
                  </td>

                  <td className=" px-2 border-slate-400">
                    <p className="text-xs text-center">{val.email}</p>
                  </td>
                  <td className=" px-2 border-slate-400">
                    <p className="text-xs text-center">{val.role}</p>
                  </td>
                  <td>
                    <div className="w-full h-10 flex px-2 place-content-center place-items-center gap-1">
                      {!userAdmin.edit_admin ? (
                        <></>
                      ) : (
                        <Tooltip title="Edit password">
                          <IconButton
                            onClick={() => {
                              console.log(val);
                              setEditUser(val);
                            }}
                            size="small"
                          >
                            <BiLock />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!userAdmin.delete_admin ? (
                        <></>
                      ) : (
                        <Tooltip title="Delete user">
                          <IconButton
                            onClick={() => {
                              setDeleteUser(val);
                            }}
                            size="small"
                          >
                            <MdDeleteOutline />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {editUser && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setEditUser(null);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[30%] w-[95%] h-[40vh] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div
                className=" w-full h-full bg-white rounded-sm absolute z-[99999]
          flex flex-col place-content-center p-3 gap-7"
              >
                <div className="flex flex-col place-items-center">
                  <p className="text-lg text-black font-medium">
                    Reset Password ?
                  </p>
                  <p className="text-xs text-slate-500">
                    Fill in a new password to change your password
                  </p>
                </div>
                <FormControl variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    size="small"
                  >
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      borderRadius: 0,
                      height: "40px",
                    }}
                    fullWidth
                    size="small"
                    id="outlined-adornment-password"
                    label="New Password"
                    type={showpass ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showpass ? (
                            <MdOutlineVisibilityOff size={15} />
                          ) : (
                            <MdOutlineVisibility size={15} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </FormControl>

                <div className="flex place-content-center gap-2">
                  <Button
                    fullWidth
                    onClick={() => {
                      setEditUser(null);
                    }}
                    sx={useStyle.OutlineButton}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    onClick={() => {
                      updateUserPassword();
                    }}
                    sx={useStyle.Button}
                    endIcon={
                      updatePassword ? <CircularProgress size={14} /> : <></>
                    }
                  >
                    Change Password
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {deleteUser && (
          <div className="fixed w-full h-full top-0 left-0 flex place-content-center place-items-center">
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-[999] bg-slate-500/50 backdrop-blur-sm"
              onClick={() => {
                setDeleteUser(null);
              }}
            />
            <motion.div
              {...framerSidebarPanel}
              className="absolute sm:w-[25%] w-[95%] h-[25%] flex flex-col place-content-center z-[9999]"
              aria-label="Account"
            >
              <div className="w-full h-full bg-white absolute flex flex-col place-content-center place-items-center gap-10">
                <div className="flex flex-col place-content-center place-items-center gap-3">
                  <p className="text-lg text-black font-medium">
                    Delete Account?
                  </p>
                  <p className="text-xs text-slate-400">
                    Once account is deleted it cannot be recovered
                  </p>
                </div>

                <div className="flex w-full px-3 place-content-center">
                  <div className="sm:w-3/4 w-full gap-2 flex">
                    <Button
                      fullWidth
                      sx={useStyle.OutlineButton}
                      onClick={() => {
                        setDeleteUser(null);
                      }}
                      variant="outlined"
                    >
                      <p>Cancel</p>
                    </Button>
                    <Button
                      fullWidth
                      sx={useStyle.Button}
                      onClick={() => {
                        deleteTheUser();
                      }}
                    >
                      <p className="text-white">Yes</p>
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};

export default TeamMember;
